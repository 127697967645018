.banner {
  height: 100vh;
  width: 100%;
}

.signin {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.signin__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.signin__msg {
  font-size: 1.8rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.signin__container form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.signin__container form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  /* max-width: 220px; */
  border-radius: 5px;
  margin: 0 2px;
}

.signin__container form input::placeholder {
  color: #111;
}

.signin__container form > button {
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.signin__container form > button:hover {
  background-color: #2556cc;
}

.signin__container > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.signin__formpassword {
  display: flex;
}

.signin__formpassword > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.signin__formpassword > input::placeholder {
  color: #111;
}

.signin__formpassword button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  padding: 0 10px;
}

.signin__help__password > span {
  padding-left: 10px;
}

.signin__help__signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.signin__help__signup p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.signin__help__signup span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones and Tablet*/

@media (max-width: 799px) {
  .signin {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .signin__container {
    color: #fff;
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    max-height: 600px;
    max-width: 600px;
    border-radius: 10px;
  }

  .signin__msg {
    padding-bottom: 0px;
  }

  .signin__container form {
    flex-direction: column;
    width: 100%;
  }

  .signin__container form > input {
    width: 100%;
    margin: 10px 0;
  }

  .signin__container form > button {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
  }

  .signin__formpassword {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .signin__formpassword > input {
    display: flex;
    width: 100%;
  }

  .signin__help__password > span {
    padding-left: 10px;
  }

  .signin__help__signup {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .signin {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .signin__container {
    color: #fff;
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    max-height: 600px;
    max-width: 600px;
    border-radius: 10px;
  }

  .signin__msg {
    padding-bottom: 0px;
  }

  .signin form {
    flex-direction: column;
  }

  .signin form > input {
    max-width: 250px;
    margin: 10px 0;
  }

  .signin form > button {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
  }

  .signin__help__password > span {
    padding-left: 10px;
  }

  .signin__help__signup {
    flex-direction: column;
  } */
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
