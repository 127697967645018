* {
  color: white;
}
    
.tv {
  display: flex;
  margin: 0;
  padding: 20px;
}

.tv__player__container {
  flex: 0.8;
  width: 100%;
  height: 1000px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 60px;
  z-index: 99;
}

.tv__player {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tv__player > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__player__buttons {
  display: flex;
  padding: 20px 0;
  justify-content: center;
}

.tv__player__buttons > button {
  margin: 0 10px;
  background-color: #dbd1b4;
  padding: 10px 20px;
  color: #111;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transform: all 0.5s ease;
}

.tv__player__buttons > button:hover {
  background-color: #2556cc;
  color: white;
  transform: scale(1.05);
}

.tv__player__details__content {
  padding: 20px;
}

.tv__player__details__content > h1 {
  padding: 20px 0;
  font-size: 1.8rem;
}

.tv__player__details__content__author {
  font-size: 1rem;
  opacity: 0.7;
  font-weight: 200;
}

.tv__player__details__content__description {
  font-size: 1.2rem;
  padding-bottom: 10px;
  line-height: 1.6rem;
  font-weight: 200;
}

.tv__player__details__content__director,
.tv__player__details__content__starring {
  font-size: 1rem;
  padding: 10px 0;
}

.tv__player__details__content__director > span,
.tv__player__details__content__starring > span {
  opacity: 0.7;
}

.tv__player__details__content__stats {
  padding: 10px 0;
  font-size: 1.2rem;
}

.tv__videolist {
  flex: 0.2;
  padding: 0 20px;
}

.tv__videolist > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__videolist__container {
  width: 90%;
  padding: 20px;
  flex-direction: column;
}

/* .tv__videolist__posters {
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
} */

/* .tv__videolist__posters::-webkit-scrollbar {
  display: none;
} */

.tv__videolist__poster {
  max-height: 200px;
  border-radius: 12px;
}

.tv__videolist__poster__props {
  max-width: auto;
  height: 320px;
  transition: transform 450ms;
}

.tv__videolist__poster__props:hover {
  transform: scale(1.05);
}

.tv__videolist__poster__title {
  max-width: 200px;
  padding-left: 20px;
  padding-top: 10px;
}

.tv__videolist__poster__details {
  max-width: 250px;
  display: inline;
  transition: all ease 0.5s;
  font-size: 0.8rem;
}

/* .tv__videolist__poster__props:hover > .tv__videolist__poster__details {
  display: inline;
} */

.tv__videolist__poster__genres {
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  max-width: 250px;
}

.tv__videolist__poster__synopsis {
  display: none;
}

.tv__videolist__poster__stats {
  padding-left: 20px;
  max-width: 250px;
}


/* Phones and Tablet*/
@media (max-width: 600px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1;
    top: 0px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1;
    top: 0px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
    
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1;
    top: 60px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
    
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Desktop */
@media (min-width: 1300px) {
  .tv {
    display: flex;
    margin: 0;
    padding: 20px;
  }

  /* .tv__player {
    flex: 0.8;
    position: -webkit-relative;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    padding-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  } */

  .tv__videolist {
    flex: 0.2;
    padding: 0 20px;
  }
  
  .tv__videolist > p {
    padding: 0 0 20px 20px;
    font-size: 1.2rem;
    width: 150px;
  }
  
  .tv__videolist__container {
    width: 80%;
    padding: 0;
    flex-direction: column;
  }
  
  .tv__videolist__poster {
    max-height: 150px;
    border-radius: 12px;
  }
  
  .tv__videolist__poster__props {
    max-width: auto;
    height: 250px;
    transition: transform 450ms;
  }
  
  .tv__videolist__poster__props:hover {
    transform: scale(1.05);
  }
  
  .tv__videolist__poster__title {
    max-width: auto;
    padding-left: 20px;
    padding-top: 10px;
  }
  
  .tv__videolist__poster__details {
    max-width: auto;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: auto;
  }
}

