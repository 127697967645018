.rowcortotendencia {
  color: white;
  padding-top: 40px;
}

.rowcortotendencia__container > h2 {
  padding-left: 40px;
  padding-bottom: 40px;
}

.rowcortotendencia__container {
  display: flex;
  /* justify-content: space-between; */
}

/* .rowcortotendencia__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
} */

.rowcortotendencia__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.rowcortotendencia__posters::-webkit-scrollbar {
  display: none;
}

.rowcortotendencia__poster {
  max-height: 400px;
  border-radius: 0px;
  padding-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.rowcortotendencia__poster__props {
  height: 480px;
  /* height: 550px; */
  transition: transform 450ms;
  padding-left: 20px;
}

.rowcortotendencia__poster__props:hover {
  transform: scale(1.07);
}

.rowcortotendencia__poster__title {
  max-width: 250px;
  padding-left: 5px;
  padding-top: 20px;
  align-items: center;
}

.rowcortotendencia__poster__details {
  display: none;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__title {
  display: none;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__details {
  display: none;
  /* display: inline; */
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__title {
  display: inline;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__description {
  font-size: 0.9rem;
  font-weight: lighter;
  padding-left: 5px;
  padding-top: 10px;
  max-width: 250px;
  opacity: 0.7;
}

/* .rowcortotendencia__poster__stats {
  padding-left: 20px;
} */

.rowcortotendencia__poster__buttons {
  display: flex;
}

/* .rowcortotendencia__posterLarge {
  max-height: 250px;
}

.rowcortotendencia__posterLarge:hover {
  transform: scale(1.09);
} */

.rowcortotendencia__poster__left,
.rowcortotendencia__poster__right {
  position: absolute;
  width: 40px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding: 30px 0;
  margin-top: 40px;
}

.rowcortotendencia__poster__left {
  left: 0;
}

.rowcortotendencia__poster__right {
  right: 0;
}

.rowcortotendencia:hover .rowcortotendencia__poster__left,
.rowcortotendencia:hover .rowcortotendencia__poster__right {
  opacity: 1;
}

@media (max-width: 760px) {
  .rowcortotendencia__poster__left,
  .rowcortotendencia__poster__right {
    opacity: 1;
  }
}

.rowcortotendencia__player {
  padding-bottom: 40px;
}

/* Phones and Tablet*/
@media (max-width: 799px) {
  .rowcortotendencia__container > h2 {
    padding-left: 0;
    text-align: center;
    justify-content: center;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .rowcortotendencia__poster__props {
    height: auto;
  }

  .rowcortotendencia__poster__title {
    display: none;
  }

  .rowcortotendencia__poster__details {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__title {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__props:hover
    > .rowcortotendencia__poster__details {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__title {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__description {
    display: none;
  }

  .rowcortotendencia__poster__left,
.rowcortotendencia__poster__right {
  display: none;
}
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
