input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8.8px 0;
  background-color: var(--teleboing-nine);
  height: 8px;
  border-radius: 5px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  margin-top: -4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}

input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}

input[type=range]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}


.prebannerTvIntro {
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
}

.bannerTvIntro {
  position: relative;
  height: 100vh;
  color: var(--teleboing-zero);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
}

.react-player {
  background-size: cover;
  background-position: center center;
  position: absolute;
  object-fit: contain;
  z-index: 0;
  width: 100%;
  height: auto;
}

.noHover{
  pointer-events: none;
}

.bannerTvIntro__frame {
  display: flex;
  object-fit: contain;
  position: absolute;
  justify-content: center;
  width: 100%;
}

.bannerTvIntro__nav {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  height: auto;
  z-index: 1000;
  color: white;
  background-color: black;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
  border-bottom: solid 1px var(--teleboing-nine);
  width: 100%;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.bannerTvIntro__nav__black {
  background-color: rgba(17, 17, 17, 0);
}

.bannerTvIntro__nav__contents {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
}

.bannerTvIntro__logo {
  width: 120px;
  cursor: pointer;
}

.bannerTvIntro__nav__contents__right {
  display: flex;
}

.bannerTvIntro__nav__contents__right__accesos {
    display: flex;
}

.bannerTvIntro__nav__contents__right__ticker {
  height: auto;
  overflow: hidden;
  position: relative;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  font-weight: 400;
  font-family: Paytone One;
  display: flex;
  align-items: center;
  right: 10px;
  width: 450px;
  white-space: nowrap;
  text-overflow: clip;
  opacity: 0.9;
}

.bannerTvIntro__nav__contents__right__ticker p {
  position: absolute;
  font-family: Paytone One;
  width: auto;
  height: auto;
  margin: 0;
  font-weight: 400;
  text-align: left;
  transform: translateX(100%);
  animation: overflow-scroll1 100s linear infinite;
}

@keyframes overflow-scroll1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bannerTvIntro__nav__contents__right__ticker p > span {
  color: var(--teleboing-one);
  padding: 0 20px;
  font-size: 1rem;
  align-items: center;
  margin: 0;
}

.bannerTvIntro__mute {
  margin: 0 5px;
}

.bannerTvIntro__author {
  font-size: 3rem;
  font-weight: 800;
  width: auto;
  padding: 20px 0;
}

.bannerTvIntro__buttons {
  display: flex;
  width: 100%;
}

.bannerTvIntro__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
}

.bannerTvIntro__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

.bannerTvIntro__title {
  font-size: 1.2rem;
  padding: 20px 0;
  max-width: auto;
  font-weight: 600;
}

.bannerTvIntro__description {
  line-height: 1.3;
  font-size: 1rem;
  max-width: auto;
  font-weight: 400;
}

.bannerTvIntro__timestamp {
  display: flex;
  padding: 0 20px;
  font-size: 1rem;
  max-width: auto;
  align-items: center;
  margin: 0;
}

.bannerTvIntro__nav__logo {
  height: fit-content;
  width: 150px;
  padding: 15px 20px 10px 20px;
  cursor: pointer;
}


.bannerTvIntro__fullscreen {
  cursor: pointer;
  margin: 0 10px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 20px;
}

.bannerTvIntro__volumen {
  cursor: pointer;
  margin: 0 10px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 22px;
}

.bannerTvIntro__volumendesktop {
  cursor: pointer;
  margin: auto 10px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 24px;
}

.bannerTvIntro__menu__option {
  max-height: 80vh;
  overflow-y: auto;
  padding: 40px 0;
}

.bannerTvIntro__menu__option__username {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.bannerTvIntro__menu__option p {
  font-size: 0.8rem;
  font-weight: 200;
  padding: 40px 20px 20px 20px;
  opacity: 0.6;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.bannerTvIntro__menu__option__link {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
}

.bannerTvIntro__menu__option__link > a {
  display: flex;
  align-items: center;
  color: var(--teleboing-zero);
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  margin: auto auto;
}

.bannerTvIntro__menu__option__link > a:hover {
  color: var(--teleboing-one);
}

.bannerTvIntro__menu__icon__linkdom {
  display: flex;
  align-items: center;
  color: var(--teleboing-zero);
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  margin: auto auto;
  padding: 2px 0;
}

.bannerTvIntro__menu__icon__linkdom:hover {
  color: var(--teleboing-one);
}

.bannerTvIntro__menu__icon__img {
  padding: 0 10px;
  width: 25px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center; display: flex;
}

.bannerTvIntro__nav__contents__channels {
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.bannerTvIntro__nav__contents__channels__selection {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(17, 17, 17, 0.7);
  border-radius: 0 0 15px 15px;
  top: 40px;
  padding: 10px;
}

.bannerTvIntro__nav__contents__channels__selectionicon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  transition: all 0.5s ease;
}

.bannerTvIntro__nav__contents__channels__selectionicon:hover {
  transition: all 0.5s ease;
  transform: scale(1.09);
}

.bannerTvIntro__nav__contents__channels__img {
  display: flex;
  align-items:center;
  margin: auto 0;
  padding: 0 20px;
}

.bannerTvIntro__channels {
  display: flex;
  cursor: pointer;
  padding: 0;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items:center;
  margin: auto 0;
}

.bannerTvIntro__datavideo {
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0;
  border-top: solid 1px var(--teleboing-nine);
  border-radius: 50px 50px 0 0;
  width: auto;
  background-color: black;
  z-index: 1;
}

.bannerTvIntro__datavideo {
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0;
  border-top: solid 1px var(--teleboing-nine);
  border-radius: 50px 50px 0 0;
  width: auto;
  background-color: black;
  z-index: 1;
}

.bannerTvIntro__datavideo__author {
  flex: 0.2;
}

.bannerTvIntro__datavideo__author h2 {
  font-size: 1.4rem;
  font-family: Paytone One;
  font-weight: 200;
  text-transform: uppercase;
}

.bannerTvIntro__datavideo__info {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}

.bannerTvIntro__datavideo__info__title h1 {
  font-size: 1.2rem;
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  padding-bottom: 10px;
}

.bannerTvIntro__datavideo__info__ticker {
  display: flex;
  align-items: center;
  margin: 0 20px;
  font-size: 1.2rem;
  width: 100%;
  justify-content: center;
}

.bannerTvIntro__ticker {
  height: auto;
  overflow: hidden;
  position: relative;
  color: var(--teleboing-zero);
  background-color: var(--teleboing-one);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 360px;
  white-space: nowrap;
  text-overflow: clip;
  padding: 10px 20px;
  border-radius: 20px;
}

.bannerTvIntro__ticker p {
  font-family: Poppins;
  width: auto;
  height: auto;
  margin: 0;
  font-weight: 400;
  text-align: left;
  transform: translateX(100%);
  animation: overflow-scroll2 150s linear infinite;
}

@keyframes overflow-scroll2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bannerTvIntro__progress {
  display: flex;
  padding-left: 10px;
  width: 100px;
}

.bannerTvIntro__progress__counter1 {
  font-size: 1rem;
  font-weight: 200;
  color: var(--teleboing-six);
  padding: 0 5px;
}

.bannerTvIntro__progress__counter2 {
  font-size: 1rem;
  font-weight: 200;
}

.bannerTvIntro__datavideo__category {
  flex: 0.2;
  width: 100%;
  display: flex;
}

.bannerTvIntro__datavideo__category p {
  font-size: 1rem;
  font-family: Paytone One;
  font-weight: 400;
  letter-spacing: 1px;
  border: solid 2px var(--teleboing-three);
  background-image: linear-gradient(to right, var(--teleboing-five) 0%, black 100%);
  border-radius: 50px; 
  padding: 10px 40px;
  color: var(--teleboing-zero);
  width: auto;
  text-transform: uppercase;
}

.bannerTvIntro__row {
  color: white;
  padding: 0 0 80px 0;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}

.bannerTvIntro__row__container > h2 {
  padding: 40px 20px;
  font-family: Poppins;
  font-weight: bolder;
  font-size: 1.4rem;
  opacity: 0.8;
  color: var(--teleboing-three);
}

.row__banner__container {
  display: flex;
}

.bannerTvIntro__nav__contents__iconos {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  /* border: solid 1px var(--teleboing-three); */
  border: none;
  /* border-radius: 20px; */
  /* background-color: var(--teleboing-six); */
}

.bannerTvIntro__row {
  display: flex;
  padding: 60px 20px;
}

.bannerTvIntro__row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.bannerTvIntro__row__posters::-webkit-scrollbar {
  display: none;
}

.bannerTvIntro__row__poster {
  max-height: 250px;
  border-radius: 12px;
  cursor: pointer;
  border-radius: 20px;
}

.bannerTvIntro__row__poster:hover {
  transform:translateY(-50px) ;
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__props {
  height: 100%;
}

.bannerTvIntro__row__poster__props__catselected {
  display: flex;
  font-size: 1rem;
  border: solid 2px var(--teleboing-two);
  background-color: var(--teleboing-two);
  width: auto;
  border-radius: 20px;
  padding: 10px 20px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.bannerTvIntro__row__poster__props__category {
  display: flex;
  font-size: 1rem;
  border: solid 2px var(--teleboing-three);
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: space-between;
  width: auto;
  border-radius: 20px;
  padding: 10px 20px;
}

.bannerTvIntro__row__poster__props__category:hover {
  cursor: pointer;
  background-color: var(--teleboing-three);
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__props__category img  {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  padding: 0 20px;
}                            

.bannerTvIntro__row__poster__author {
  display: flex;
  max-width: 300px;
  padding-left: 20px;
  padding-top: 10px;
  opacity: 0.8;
}

.bannerTvIntro__row__poster__details {
  max-width: 280px;
  padding-left: 20px;
  padding-top: 10px;
}

.bannerTvIntro__row__poster__title {
  font-size: 1.2rem;
  font-weight: 400;
}

.bannerTvIntro__row__poster__title > span {
  font-size: 1rem;
  font-weight: 200;
  opacity: 0.8;
}

.bannerTvIntro__row__poster__left,
.bannerTvIntro__row__poster__right {
  position: absolute;
  width: 40px;
  height: 270px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.bannerTvIntro__row__poster__left {
  left: 0;
}

.bannerTvIntro__row__poster__right {
  right: 0;
}

.bannerTvIntro__row:hover .bannerTvIntro__row__poster__left,
.bannerTvIntro__row:hover .bannerTvIntro__row__poster__right {
  opacity: 1;
}

.bannerTvIntro__row__poster__more {
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
  padding: 20px;
  border: solid 2px var(--teleboing-three);
  border-radius: 20px;
  cursor: pointer;
}

.bannerTvIntro__row__poster__more:hover {
  border: solid 2px var(--teleboing-four);
  background-color: var(--teleboing-four);
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__more > p {
  display: flex;
  align-items: center;
  margin: auto 0;
  font-size: 1.2rem;
  justify-content: left;
}

.bannerTvIntro__preload {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  /* justify-content: space-between; */
  align-items: center;
  margin: auto auto;
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #111, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #111, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bannerTvIntro__preload__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 400px;
  padding: 0;
  border-radius: 50%;
}

.bannerTvIntro__preload__logo {
  display: flex;
  margin: 0 auto;
  /* height: fit-content; */
  width: 200px;
  padding: 40px 0;
  justify-content: center;
}

.volume-icon-container {
  display: flex;
  padding: 0 20px;
}

.volume-control {
  display: flex;
}

.volume-control__slider {
  display: flex;
  margin: auto 0;
  align-items: center;
}


/* Phones and Tablet*/
@media (max-width: 899px) {
  .bannerTvIntro {
  position: relative;
  height: 330px;
  color: var(--teleboing-zero);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
    display: none;
  }

  .bannerTvIntro__preload {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
  }
  
  .bannerTvIntro__preload__img {
    width: 100%;
    height: auto;
  }

  .bannerTvIntro__channels {
    padding: 0;
  }

  .bannerTvIntro__volumen {
    display: none;
  }

  .bannerTvIntro__nav__contents {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
  }

  .bannerTvIntro__nav__contents__channels {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  }

  .bannerTvIntro__nav__contents__channels__selection {
    padding-top: 20px;
    top: 50px;
  }

  .prebanner {
    width: 100%;
    height: auto;
  }

  .banner {
    height: 100vh;
  }

  .bannerTvIntro__frame {
    display: flex;  
    flex-direction: column;
  }

  .react-player {
    background-size: cover;
    background-position: center center;
    position: absolute;
    object-fit: contain;
    z-index: 0;
  }

  .bannerTvIntro__nav {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: auto;
    z-index: 1000;
    color: white;
    background-color: black;
    border: none;
    /* background-color: rgba(17, 17, 17, 0.97); */
    /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
    width: auto;
  }
  
  .bannerTvIntro__nav__black {
    background-color: rgba(17, 17, 17, 0);
    width: auto;
  }

  .bannerTvIntro__dropdown {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .bannerTvIntro__nav__contents {
    display: flex;
    padding: 0;
    width: auto;
  }
  
  .bannerTvIntro__container__menu {
    display: flex;
    position: relative;
  }
  
  .bannerTvIntro__menu {
    display: flex;
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
    align-items: center;
    width: 25px;
  }

  .bannerTvIntro__menu-box {
    position: absolute;
    top: calc(100%);
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    /* border-radius: 0 0 50px 50px; */
  }
  
  .bannerTvIntro__close-icon {
    position: absolute;
    top: -1.5rem;
    right: 1rem;
    padding: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .bannerTvIntro__menu__close {
    width: 40px;
    padding: 40px 0;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  }

  .bannerTvIntro__mobile__signup {
    margin: 0;
    color: #fff;
    display: flex;
    padding: 40px 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .bannerTvIntro__mobile__signup form {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .bannerTvIntro__mobile__signup form > button {
    margin: 5px;
    padding: 15px 20px;
    border: 2px solid var(--teleboing-three);
    background-color: transparent;
    cursor: pointer;
    color: var(--teleboing-zero); 
    border-radius: 50px;
    font-size: 1rem;
    transition: all 0.7s ease;
  }
  
  .bannerTvIntro__mobile__signup form > button:hover {
    background-color: var(--teleboing-three);
    border: 2px solid var(--teleboing-three);
    color: var(--teleboing-zero); 
    transition: all 0.7s ease;
  }

  .bannerTvIntro__datavideo__mobile {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    width: auto;
    background-color: black;
    z-index: 1;
  }

  .bannerTvIntro__datavideo__author__mobile {
    padding-bottom: 5px;
  }

  .bannerTvIntro__progress__mobile {
    display: flex;
  }

  .bannerTvIntro__progress__counter1__mobile {
    font-size: 1rem;
    font-weight: 200;
    color: var(--teleboing-six);
    padding-right: 10px;
  }
  
  .bannerTvIntro__progress__counter2__mobile {
    font-size: 1rem;
    font-weight: 200;
    opacity: 0.8;
  }

  .bannerTvIntro__datavideo__description__mobile {
    padding: 20px 40px;
    font-size: 1rem;
    opacity: 0.8;
    line-height: 1.6rem;
    font-weight: 200;
    
  }

  .bannerTvIntro__datavideo__description__mobile p > span {
    background-color: #fff;
    margin: 0 10px;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0 10px;
    color: #111;
    border-radius: 10px;

  }

   .bannerTvIntro__datavideo__author h2 {
    font-size: 1.2rem;
    letter-spacing: 1px;
   }

   .bannerTvIntro__datavideo__info {
    width: 100%;
    margin: 0;
    padding: 0;
   }

   .bannerTvIntro__datavideo__info__title {
    display: none;
   }

   .bannerTvIntro__datavideo__info__title h1 {
    font-size: 0.9rem;
    display: flex;
    font-family: Poppins;
    font-weight: 200;
    padding-bottom: 10px;
   }

   .bannerTvIntro__datavideo__info__ticker {
    display: flex;
    
    flex-direction: column;
    width: 100%;
   }

   .bannerTvIntro__timestamp {
    display: none;
   }

   .bannerTvIntro__ticker {
    display: none;
    width: 100%;
    margin: 5px 0;
   }

   .bannerTvIntro__ticker p {
    font-family: Poppins;
    width: 100%;
    height: auto;
    margin: 0;
    font-weight: 400;
    text-align: left;
    justify-content: center;
    transform: translateX(100%);
    animation: overflow-scroll3 20s linear infinite;
  }
  
  @keyframes overflow-scroll3 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
   .bannerTvIntro__datavideo {
    display: none;
   }

   .bannerTvIntro__nav__contents__right__ticker {
    display: none;
   }

   .bannerTvIntro__nav__contents__ticker {
    width: 100%;
    margin: 0;
   }

   .bannerTvIntro__datavideo__counter {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 5px 0;
   }

   .bannerTvIntro__nav__contents__iconos {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 0;
  }

  .bannerTvIntro__mute {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding-left: 10px;
  }

  .bannerTvIntro__row {
    flex-direction: column;
  }

  .bannerTvIntro__row__poster__props {
    width: 300px;
    height: 100%;
  }

  .bannerTvIntro__row__poster__details {
    max-width: 250px;
    padding-left: 20px;
    padding-top: 10px;
  }

  .bannerTvIntro__row__poster__more {
    margin: 20px 0;
  }

  .bannerTvIntro__row__poster__more > p {
    justify-content: center;
    margin: 0 auto;
  }

}

/* Tablet */
@media (min-width: 900px) and (max-width: 1023px) {
  .bannerTvIntro {
    position: relative;
    height: 560px;
    color: var(--teleboing-zero);
    object-fit: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
  }

  .bannerTvIntro__container__menu {
    display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidomobile {
    display: none;
  }
  .bannerTvIntro__datavideo__info__title {
    display: none;
  }

  .bannerTvIntro__datavideo__mobile {
    display: none;
  }

  .bannerTvIntro__datavideo__description__mobile {
  display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
  display: flex;
  align-items: center;
  margin: auto 0;
  }

  .volume-icon-container {
    align-items: center;
    margin: auto 0;
    display: flex;
  }

  .bannerTvIntro__volumendesktop {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}

/* Laptop */
@media (min-width: 1024px) {
  .bannerTvIntro__container__menu {
    display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidomobile {
    display: none;
  }
  .bannerTvIntro__datavideo__info__title {
    display: none;
  }

  .bannerTvIntro__datavideo__mobile {
    display: none;
  }

  .bannerTvIntro__datavideo__description__mobile {
  display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
  display: flex;
  align-items: center;
  margin: auto 0;
  }

  .volume-icon-container {
    align-items: center;
    margin: auto 0;
    display: flex;
  }

  .bannerTvIntro__volumendesktop {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}