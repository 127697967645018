.column {
  color: white;
  max-width: 800px;
  /*  padding-left: 20px; */
}

.column__container {
  text-align: center;
  justify-content: center;
  /* justify-content: space-between; */
}

/* .column__container__player {
  z-index: 99;
  top: 80px;
  position: sticky;
  align-self: flex-start;
  padding: 20px 0;
} */

.column__posters {
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
  text-align: center;
  justify-content: center;
}

.column__posters::-webkit-scrollbar {
  display: none;
}

.column__poster {
  max-width: 800px;
}

.column__poster__props {
  height: 450px;
  /* max-width: 450px; */
  transition: transform 450ms;
}

.column__poster__props:hover {
  transform: scale(1.09);
}

.column__poster__title {
  /* max-width: 400px; */
  padding-top: 10px;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
}

.column__poster__details {
  max-width: 350px;
  display: none;
  transition: all ease 0.5s;
  text-align: center;
}

.column__poster__props:hover > .column__poster__details {
  display: inline;
}

.column__poster__description {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 20px;
  width: 400px;
  justify-content: center;
  text-align: center;
}

/* .column__poster__stats {
  padding-left: 20px;
} */

/* .column__banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.column__banner__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}
 */

 @media (max-width: 799px) {
  .column {
    width: 100%;
  } 

  .column__poster {
    width: 350px;
  }

  .column__poster__props {
    height: auto;
  }
}