.linksList {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.link {
  flex-basis: 25%;
  padding: 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.linklist span > a {
  color: #808080;
  text-decoration: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .linksList {
    display: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .linksList > span {
    padding: 10px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .linksList {
    display: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .linksList > span {
    padding: 10px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
