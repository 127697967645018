.faq {
    padding: 40px 120px;
}

.faq__title {
    font-size: 1.8rem;
    color: var(--teleboing-two);
    text-align: center;
    justify-content: center;
}

.faq__container {
    display: flex;
    flex-direction: column;
}

.faq__item {
    display: flex;
    padding: 40px 0;
}

.faq__item h3 {
    font-size: 1.4rem;
    cursor: pointer;
}

.faq__item > span {
    display: flex;
    font-size: 1rem;
    color: var(--teleboing-two);
    padding: 0 10px;
    align-items: center;
}

.faq__answer {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

/* Mobile */
@media (max-width: 999px) {
    .faq {
        padding: 20px;
    }
}