.videoCard {
  color: white;
  width: 400px;
  height: 400px;
  transition: transform 400ms ease-in-out;
  margin: 0 10px;
}

.videoCard:hover {
  transform: scale(1.09);
}

.videoCard > img {
  border-radius: 12px;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.videoCard > p {
  width: 80%;
  padding-top: 5px;
  padding-left: 20px;
}

.videoCard > h2 {
  padding-top: 10px;
  padding-left: 20px;
  width: 80%;
}

.videoCard__title {
  width: 80%;
  font-weight: 400;
  padding-left: 20px;
}

.videoCard__stats {
  display: none;
}

.videoCard:hover > .videoCard__stats {
  display: inline;
}

.videoCard__description {
  display: none;
}

/* Phones */
@media (max-width: 600px) {
  .videoCard {
    color: white;
    width: 100%;
    height: auto;
    transition: transform 400ms ease-in-out;
    margin: 0;
  }

  .videoCard:hover {
    transform: scale(1);
  }

  .videoCard > img {
    border-radius: 12px;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .videoCard__title {
    width: 100%;
    font-weight: 400;
    padding: 0;
    text-align: center;
    justify-content: center;
  }

  .videoCard__author {
    text-align: center;
    justify-content: center;
  }

  .videoCard:hover > .videoCard__stats {
    display: inline;
  }

  .videoCard__description {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
