* {
  margin: 0;
}

.so1screen__box__signup {
  margin: 0;
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.so1screen__logo {
  height: fit-content;
  width: 120px;
  padding: 0 20px;
  cursor: pointer;
}

.so1screen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.so1screen__signup form {
  display: flex;
  text-align: center;
}

.so1screen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 0.25px solid white;
  background-color: transparent;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.so1screen__signup form > button:hover {
  background-color: #2556cc;
  border: 0.5px solid #111;
  color: #111; 
  transition: all 0.7s ease;
}

.so1screen__container {
  height: 100%;
  display: flex;
}

.so1screen__container2 {
  height: 100%;
  display: flex;
  padding: 0 60px;
}

.so1screen__banner {
  color: #fff;
  /* display: flex; */
  padding: 40px;
  height: 90vh;
  width: auto;
}

.so1screen__poster {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.so1screen__poster__item1 {
  height: auto;
  max-width: 250px;
  padding: 20px 0;
  justify-items: center;
  text-align: center;
}

.so1screen__poster__item2 {
  max-height: 600px;
  width: auto;
  padding: 40px;
}

.so1screen__props {
  display: flex;
  padding: 80px 40px;
}

.so1screen__details {
  width: auto;
  padding: 20px;
}

.so1screen__details__headline__banner {
  font-size: 1.2rem;
  padding: 10px 0 10px 7px;
  opacity: 0.7;
}

.so1screen__details__headline {
  font-size: 1.2rem;
  padding: 10px 0;
  opacity: 0.7;
}

.so1screen__details__title {
  font-size: 2.4rem;
}

.so1screen__details__subinfo {
  opacity: 0.7;
  font-size: 0.9;
  padding: 10px 0 30px 0;
}

.so1screen__details__description__banner {
  font-size: 1.2rem;
  padding: 20px 0 20px 7px;
  line-height: 1.8rem;
  text-shadow: 2px 2px 8px #111;
  max-width: 350px;
}

.so1screen__details__description0 {
  font-size: 1.2rem;
  padding: 0;
  line-height: 1.8rem;
  text-shadow: 2px 2px 8px #111;
}

.so1screen__details__description {
  font-size: 1.4rem;
  padding: 20px 0;
  line-height: 2rem;
}

.so1screen__details__description > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__details__genres {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.so1screen__details__genres > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__details__country {
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.so1screen__details__country > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__video {
  margin-top: 20px;
}

.so1screen__shoots {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 60px 0 100px 0;
  flex-wrap: wrap;
}

.so1screen__shoots > div {
  width: auto;
  padding: 40px;
}

.so1screen__shoots div img {
  max-width: 350px;
  transition: all 0.5s ease;
}

.so1screen__shoots div img:hover {
  transform: scale(1.1);
  transition: all 0.5s ease;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .so1screen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .so1screen__logo {
    height: fit-content;
    width: 120px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__signup {
    color: #fff;
    display: grid;
    padding: 10px;
  }
  
  
  .so1screen__poster {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    height: 300px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0;
  }

  .so1screen__poster__item2 {
    height: 300px;
    width: auto;
    padding: 0 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0;
  }

  .so1screen__banner {
    /* flex: none; */
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    /* display: grid; */
    /* flex-direction: column; */
    padding: 20px 0;
  }

  .so1screen__container {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  
  .so1screen__container2 {
    justify-content: center;
    text-align: center;
    padding: 20px 40px;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 10px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__props {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
  }

  .so1screen__video {
    margin: 20px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .so1screen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .so1screen__logo {
    height: fit-content;
    width: 120px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__signup {
    color: #fff;
    display: grid;
    padding: 10px;
  }
  
  
  .so1screen__poster {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    height: 300px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__poster__item2 {
    height: 500px;
    width: auto;
    padding: 0 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__banner {
    /* flex: none; */
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    /* display: grid; */
    /* flex-direction: column; */
    padding: 20px 0;
  }

  .so1screen__container {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  
  .so1screen__container2 {
    justify-content: center;
    text-align: center;
    padding: 20px 40px;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 40px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__props {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
  }

  .so1screen__video {
    margin: 40px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {

  .so1screen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    /* height: 500px;
    width: auto; */
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__poster__item2 {
    /* height: 500px;
    width: auto; */
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 40px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__video {
    margin-top: 80px;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 7px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
  }

  .so1screen__video {
    margin-top: 140px;
  }
}
