* {
  margin: 0;
}

.watchscreen__container {
  height: 100%;
}

.watchscreen__banner {
  color: #fff;
  display: flex;
  padding: 40px;
}

.watchscreen__poster__item {
  max-height: 350px;
  width: auto;
  margin: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.watchscreen__props {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.watchscreen__details {
  width: auto;
}

.watchscreen__details__headline {
  font-size: 1rem;
  padding-bottom: 10px;
  opacity: 0.7;
}

.watchscreen__details__title {
  font-size: 2.4rem;
}

.watchscreen__details__subinfo {
  opacity: 0.7;
  font-size: 0.7;
  padding-bottom: 30px;
}

.watchscreen__details__description {
  font-size: 1.2rem;
  padding-bottom: 20px;
  line-height: 1.7rem;
}

.watchscreen__details__description > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__details__genres {
  font-size: 1rem;
  padding-bottom: 10px;
}

.watchscreen__details__genres > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__details__country {
  font-size: 1rem;
  padding-bottom: 20px;
}

.watchscreen__details__country > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__video {
  margin-bottom: 80px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    height: 450px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}
