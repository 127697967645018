@import url("https://fonts.googleapis.com/css?family=Rubik");

* {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif, Lighter, Light, Regular, Medium, Bold;
  /* font-family: "Asap Condensed", sans-serif; */
  /* font-family: "Trebuchet MS", sans-serif; */
}

.app {
  /* background-color: #111; */
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(to right, black, #111);
  z-index: -1; */
}
