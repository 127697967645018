.rowcortoestreno {
  color: white;
  padding-top: 30px;
}

.rowcortoestreno__container > h2 {
  padding-left: 40px;
  padding-bottom: 30px;
}

.rowcortoestreno__container {
  display: flex;
  /* justify-content: space-between; */
}

/* .rowcortoestreno__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
} */

.rowcortoestreno__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.rowcortoestreno__posters::-webkit-scrollbar {
  display: none;
}

.rowcortoestreno__poster {
  max-height: 250px;
  border-radius: 0px;
  padding-right: 20px;
  cursor: pointer;
}

.rowcortoestreno__poster__props {
  height: 450px;
  transition: transform 450ms;
  padding-left: 20px;
}

.rowcortoestreno__poster__props:hover {
  transform: scale(1.07);
}

.rowcortoestreno__poster__title {
  max-width: 300px;
  padding-left: 5px;
  padding-top: 0px;
  align-items: center;
}

.rowcortoestreno__poster__details {
  /* display: none; */
  transition: all ease 1.5s;
}

.rowcortoestreno__poster__title {
  /* display: none; */
  transition: all ease 1.5s;
}

/* .rowcortoestreno__poster__props:hover > .rowcortoestreno__poster__details {
  display: inline;
  transition: all ease 1.5s;
}

.rowcortoestreno__poster__props:hover > .rowcortoestreno__poster__title {
  display: inline;
  transition: all ease 1.5s;
} */

.rowcortoestreno__poster__description {
  font-size: 0.9rem;
  font-weight: lighter;
  padding-left: 5px;
  padding-top: 10px;
  max-width: 300px;
  opacity: 0.7;
}

.rowcortoestreno__poster__stats {
  padding-left: 20px;
}

/* .rowcortoestreno__posterLarge {
  max-height: 250px;
}

.rowcortoestreno__posterLarge:hover {
  transform: scale(1.09);
} */

.rowcortoestreno__poster__left,
.rowcortoestreno__poster__right {
  position: absolute;
  width: 40px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding: 30px 0;
}

.rowcortoestreno__poster__left {
  left: 0;
}

.rowcortoestreno__poster__right {
  right: 0;
}

.rowcortoestreno:hover .rowcortoestreno__poster__left,
.rowcortoestreno:hover .rowcortoestreno__poster__right {
  opacity: 1;
}

@media (max-width: 760px) {
  .rowcortoestreno__poster__left,
  .rowcortoestreno__poster__right {
    opacity: 1;
  }
}

/* .rowcortoestreno__player {
  padding-bottom: 40px;
} */

/* Phones and Tablet*/
@media (max-width: 799px) {
  .rowcortoestreno__container > h2 {
    padding-left: 0;
    text-align: center;
    justify-content: center;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .rowcortoestreno__poster__props {
    height: auto;
    transition: transform 450ms;
    padding-left: 20px;
  }

  .rowcortoestreno__poster__left,
.rowcortoestreno__poster__right {
  display: none;
}
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
