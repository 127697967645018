.newuser__banner {
  height: 100vh;
  width: 100%;
}

.newuser {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.newuser__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.newuser__msg > span {
  opacity: 0.8;
  font-size: 1.2rem;
}

.newuser__msg__approvedpay {
  font-size: 1.8rem;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
}

.newuser__msg__subcode {
  font-size: 1.4rem;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.newuser__msg__subcode > span {
  color: var(--teleboing-two);
}

.newuser__msg__enterdata {
  font-size: 1.4rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.newuser__container form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.newuser__container form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.newuser__container form input::placeholder {
  color: #111;
}

.newuser__formpassword {
  display: flex;
}

.newuser__formpassword > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.newuser__formpassword > input::placeholder {
  color: #111;
}

.newuser__formpassword button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  padding: 0 10px;
}

.newuser__container form > button {
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.newuser__container form > button:hover {
  background-color: #2556cc;
}

.newuser__container > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.newuser__help {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.newuser__help ul li {
  padding: 5px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

/* Phones */
@media (max-width: 799px) {
  .newuser__banner {
    height: 100vh;
    width: 100%;
    margin-bottom: 40px;
  }

  .newuser {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .newuser__container {
    padding: 40px;
    width: 100%;
  }

  .newuser__msg__approvedpay {
    font-size: 1.5rem;
    padding: 20px;
  }

  .newuser__msg__subcode {
    font-size: 1.2rem;
  }
  
  .newuser__msg__enterdata {
    font-size: 1.2rem;
    padding: 0;
  }

  .newuser__container form {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
  }

  .newuser__container form > input {
    width: 100%;
    margin: 10px 0;
  }

  .newuser__container form input::placeholder {
    color: #111;
  }

  .newuser__container form > button {
    margin: 10px 0;
    width: 100%;
  }

  .newuser__help {
    padding-top: 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
