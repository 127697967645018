.banner__home {
  position: relative;
  height: 100vh;
  width: auto;
  color: #fff;
  padding-bottom: 70px;
  /* object-fit: contain; */
}

.banner__frame {
  display: flex;
  justify-content: left;
  left: 0;
  /* object-fit: contain; */
}

.banner__youtube {
  padding-bottom: 40px;
}

.banner__contents {
  margin: 0;
  padding: 50px 0 0 40px;
  height: 75vh;
  width: 40%;
}

.banner__live {
  font-size: 1.2rem;
  font-weight: 400;
  width: auto;
  color: red;
  padding-bottom: 20px;
}

.banner__pretitle {
  font-size: 1.2rem;
  font-weight: 400;
  width: auto;
  opacity: 0.7;
}

.banner__title {
  font-size: 3rem;
  padding: 20px 0;
  max-width: auto;
  font-weight: 600;
}

.banner__subtitle1 {
  font-size: 1.2rem;
  padding-bottom: 10px;
  line-height: 1.7rem;
}

.banner__subtitle1 > span {
  opacity: 0.7;
  padding-right: 10px;
}

.banner__subtitle2 {
  font-size: 1.2rem;
  padding-bottom: 20px;
  line-height: 1.7rem;
}

.banner__subtitle2 > span {
  opacity: 0.7;
  padding-right: 10px;
}

.banner__description {
  line-height: 1.5;
  font-size: 1rem;
  max-width: auto;
  font-weight: 200;
  opacity: 0.7;
}

/* .banner__timestamp {
  padding: 10px 0;
  font-size: 1rem;
  max-width: auto;
} */

/* .banner__author {
  font-size: 3rem;
  font-weight: 800;
  width: auto;
  padding: 20px 0;
} */

.banner__buttons {
  display: flex;
  width: 100%;
  padding-top: 30px;
}

.banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
  align-items: center;
}

.banner__button:hover {
  color: #000;
  font-weight: 700;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .banner__home {
    position: relative;
    color: #fff;
    padding-bottom: 70px;
  }

  .banner__frame {
    display: flex;
  }

  .banner__contents {
    margin: 0;
    padding: 20px 40px 0 40px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .banner__live {
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
    color: red;
    padding-bottom: 20px;
  }

  .banner__pretitle {
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
    opacity: 0.7;
  }

  .banner__title {
    display: flex;
    font-size: 3rem;
    padding: 20px;
    width: auto;
    font-weight: 600;
    margin: 0;
    justify-content: center;
    text-align: center;
  }

  .banner__subtitle1 {
    font-size: 1.2rem;
    padding: 10px 20px;
    line-height: 1.7rem;
  }

  .banner__subtitle1 > span {
    opacity: 0.7;
    padding-right: 0;
  }

  .banner__subtitle2 {
    font-size: 1.2rem;
    padding-bottom: 0 20px 20px 20px;
    line-height: 1.7rem;
  }

  .banner__subtitle2 > span {
    opacity: 0.7;
    padding-right: 0;
  }

  .banner__description {
    display: none;
  }

  .banner__buttons {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: -20px;
  }

  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 0.8vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 10px 0;
    padding-top: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
    align-items: center;
    font-size: 1.2rem;
  }

  .banner__youtube {
    padding-bottom: 40px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .banner__contents {
    margin: 0;
    padding: 20px 0 0 40px;
    height: auto;
    width: 70%;
  }
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;

    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
  }
  .banner__description {
    display: none;
  }
  .banner__timestamp {
    display: none;
  } */
}

/* Laptop */
@media (max-width: 992px) {
  /* .banner__contents {
    margin: 0;
    padding: 20px 0 0 40px;
    height: auto;
    width: 70%;
  }
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
  } */
}

/* Desktop */
@media (max-width: 1200px) {
}
