* {
  padding: 0;
  margin: 0;
  font-family: "Product Sans", sans-serif;
}

body, html {
  --teleboing-zero: white;
  --teleboing-one: #111/* #680080 */;
  --teleboing-two: #ED244E/* #530066 */;
  --teleboing-three: #8E152E/* #3f004d */;
  --teleboing-four: #5E0E1F;
  --teleboing-five: #2F070F;
  --teleboing-six: #886f1d/* #2a0034 */;
  --teleboing-seven:#5e4d14/* #ED244E */;
  --teleboing-eight: #342b0b/* #9200b3 */;
  --teleboing-nine: #1f1907/* #15001a */;
  color: white;
  background-image: linear-gradient(to right, #111, black);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to right, black, black); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
