.banner {
  height: 100vh;
  width: 100%;
}

.planscreen {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.planscreen__container {
  color: #fff;
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: top;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  height: auto;
  width: auto;
  border-radius: 15px;
}

.planscreen__msg > span {
  opacity: 0.8;
  font-size: 1.2rem;
}

.planscreen__msg > p {
  font-size: 1.8rem;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
}

.planscreen__options ul {
  display: flex;
  justify-content: center;
  text-align: center;
}

.planscreen__options ul button {
  padding: 10px 20px;
  margin: 20px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  transition: all 0.5s ease;
}

.planscreen__options ul button:hover {
  background-color: #2556cc;
}

.planscreen__options ul li > p {
  padding-right: 20px;
  font-size: 1.8rem;
}

.planscreen__options__price {
  font-size: 1.8rem;
  padding: 10px 0;
}

.planscreen__options__price span {
  font-size: 1rem;
}

.planscreen__punchline {
  padding: 20px 0;
}

.planscreen__punchline p {
  font-size: 1.2rem;
  font-weight: 200;
  color: var(--teleboing-three);
}

.planscreen__help {
  background-color: #111;
  width: 100%;
  height: auto;
  padding: 10px 0;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.planscreen__help p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.planscreen__help span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones */

@media (max-width: 600px) {
  .planscreen__options ul {
    flex-direction: column;
  }

  .planscreen__options ul button {
    margin: 10px 0;
  }

  .planscreen__help {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {

  .banner {
    height: 100%;
    width: 100%;
  }

  .planscreen {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100%;
    width: 100%;
  }

  .planscreen__container {
    color: #fff;
    display: flex;
    padding: 50px;
    justify-content: center;
    align-items: top;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    height: auto;
    width: auto;
    border-radius: 0;
  }

  .planscreen__options ul {
    flex-direction: column;
  }

  .planscreen__options ul button {
    margin: 10px 0;
  }

  .planscreen__help {
    flex-direction: column;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
