.banner {
  height: 100vh;
  width: 100%;
}

.resetpassword {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.resetpassword__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.resetpassword__msg {
  font-size: 1.8rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.resetpassword form {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.resetpassword form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  width: auto;
  border-radius: 5px;
}

.resetpassword form input::placeholder {
  color: #111;
}

.resetpassword form > button {
  width: auto;
  padding: 10px 10px;
  margin: 20px 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.resetpassword form > button:hover {
  background-color: #2556cc;
}

.resetpassword > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.resetpassword form > p {
  font-size: 1rem;
  opacity: 0.8;
  margin-top: 10px;
}

.resetpassword__help__password > span {
  padding-left: 10px;
}

.resetpassword__help__signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.resetpassword__help__signup p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.resetpassword__help__signup span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones and Tablet*/

@media (max-width: 600px) {
  .resetpassword__help__password > span {
    padding-left: 10px;
  }

  .resetpassword__help__signup {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
