.originals {
  color: white;
  padding: 20px 0 100px 0;
}

.originals__container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.originals__title {
  padding-left: 40px;
  padding-bottom: 0px;
}

.originals__explora {
  opacity: 0.2;
  padding-right: 20px;
}

.originals__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px 20px 20px 40px;

  width: auto;
  text-align: center;
}

.originals__posters::-webkit-scrollbar {
  display: none;
}

.originals__poster {
  object-fit: contain;
  max-height: 450px;
  cursor: pointer;
  border-radius: 0px;
}

.originals__poster__props {
  object-fit: contain;
  width: 100%;
  margin-right: 10px;
  transition: transform 450ms;
}

.originals__poster__props:hover {
  transform: scale(1.07);
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .originals__explora {
    display: none;
  }

  .originals__posters {
    margin: 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .originals__explora {
    display: none;
  }

  .originals__posters {
    margin: 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .originals__posters {
    margin: 0;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .originals__posters {
    margin: 0;
  }
}
