.loginScreen {
  margin: 0;
  padding: 0;
  color: white;
}

.loginScreen__box__signup {
  margin: 0;
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.loginScreen__logo {
  height: fit-content;
  width: 120px;
  padding: 0 20px;
  cursor: pointer;
}

.loginScreen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginScreen__signup form {
  display: flex;
  text-align: center;
}

.loginScreen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 2px solid var(--teleboing-three);
  background-color: transparent;
  cursor: pointer;
  color: var(--teleboing-zero); 
  border-radius: 50px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.loginScreen__signup form > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
  color: var(--teleboing-zero); 
  transition: all 0.7s ease;
}

.loginScreen__box__cover {
  color: white;
}

.loginScreen__cover__contents {
  font-size: 1.2rem;
  max-width: 450px;
  height: 70vh;
  padding: 100px 0 0 100px;
  z-index: 99;
}

.loginScreen__cover__contents > h1 {
  padding: 20px 0;
  z-index: 99;
}

.loginScreen__cover__contents > p {
  padding-bottom: 15px;
  font-size: 1.5rem;
  z-index: 99;
}

.loginScreen__cover__contents img {
  width: 25%;
  padding-top: 10px;
}

.loginScreen__box__s1__content {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 0;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.loginScreen__box__s1__content > h1 {
  padding: 0;
  font-size: 2rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  
}

.loginScreen__box__s1__content > p {
  padding: 20px 0;
  font-size: 1.4rem;
  line-height: 2rem;
}

.loginScreen__box__s1__content p > span {
  font-size: 0.8rem;
}

.loginScreen__box__s1__content > span {
  font-size: 1.4rem;
  padding-bottom: 20px;
  /* opacity: 0.7; */
  color: var(--teleboing-two);
  line-height: 2rem;
}

.loginScreen__box__s1__content > button {
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1.2rem;
  border-radius: 50px;
  border: 2px solid var(--teleboing-three);
  background-color: var(--teleboing-one);
  color: var(--teleboing-zero);
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__s1__content > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
}

.loginScreen__box__s1__cover > img {
  width: 100%;
  padding: 20px 0;
}

.loginScreen__box__s2 {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: flex-start;
  padding: 40px 0 20px 0;
  flex-direction: column;
}

.loginScreen__box__s2__content {
  padding: 20px 0;
  max-width: 900px;
}

.loginScreen__box__s2__content > h2 {
  font-size: 2rem;
  padding: 20px 0;
  line-height: 2.8rem;
  color: var(--teleboing-two);
  opacity: 0.9;
}

.loginScreen__box__s2__content > p {
  font-size: 1.4rem;
  line-height: 2rem;
}

.loginScreen__box__s2__iframe {
  padding: 0 40px;
}

.loginScreen__box__s2__iframeresponsive {
  width: 100%;
  height: 100%;
}

.loginScreen__box__s4 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 0 20px 0;
  flex-direction: column;
}

.loginScreen__box__s4 > div {
  width: auto;
  padding: 40px 120px;
  flex-direction: column;
}

.loginScreen__box__s4 div > h3 {
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  text-align: center;
  padding: 20px 0;
}

.loginScreen__box__s5 {
  width: 80%;
  padding: 100px 80px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.loginScreen__box__s5 > h3 {
  padding-bottom: 40px;
  text-align: center;
}

/*.react-tabs--selected {
  background: var(--teleboing-three);
  border-color: var(--teleboing-three);
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px var(--teleboing-three);
  border-color: var(--teleboing-three);
  outline: none;
} */

.loginScreen__box__s6__content {
  margin: 40px 0 80px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loginScreen__box__s6__content > p {
  padding: 20px 0;
  font-size: 1.4rem;
}

.loginScreen__box__s6__content > button {
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1.2rem;
  border-radius: 50px;
  border: 2px solid var(--teleboing-three);
  background-color: transparent;
  color: var(--teleboing-zero); 
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__s6__content > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
}

/* .loginScreen__box__shop {
  width: 100%;
  height: 100%;
  color: white;
  margin: 0 0 80px 0;
}

.loginScreen__box__shop__container {
  margin: 0;
  align-items: center;
}

.loginScreen__box__shop__content {
  padding: 60px 0;
  width: 40%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__content > h2 {
  font-size: 2.2rem;
  padding: 20px 0;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__content > h4 {
  font-size: 1.8rem;
  padding-bottom: 20px;
}

.loginScreen__box__shop__content > p {
  font-size: 1.5rem;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__img {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.loginScreen__box__shop__img > img {
  width: 15%;
  padding: 0 10px;
  transition: transform 450ms;
  border-radius: 40px;
}

.loginScreen__box__shop__img > img:hover {
  transform: scale(1.25);
}

.loginScreen__box__shop__content > button {
  padding: 15px 20px;
  margin: 25px 0;
  font-size: 1rem;
  color: #fff;
  border-radius: 10px;
  background-color: #111;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__shop__content > button:hover {
  background-color: black;
  transition: all 0.5s ease;
} */

/* Phones and Tablet*/

@media (max-width: 899px) {
}

/* Tablet and small laptop */
@media (max-width: 999px) {
  .loginScreen__headline .loginScreen__headline__usersconnected {
    display: none;
  }
  .loginScreen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .loginScreen__logo {
    height: fit-content;
    width: 120px;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .loginScreen__signup {
    display: none;
    color: #fff;
    /* display: grid; */
    padding: 10px;
  }

  .loginScreen__box__divcover {
    display: none;
  }

  .loginScreen__box__cover {
    display: none;
  }

  .loginScreen__cover__contents {
    display: none;
  }

  .loginScreen__box__s1__content {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    width: auto;
    display: flex;
    flex-direction: column;
  }

  .loginScreen__box__s1__content > h2 {
    padding: 10px 0;
    font-size: 1.8rem;
  }

  .loginScreen__box__s1__content > p {
    padding: 20px 0;
    font-size: 1.4rem;
  }

  .loginScreen__box__s1__content > span {
    font-size: 1.4rem;
    padding: 10px 0;
  }

  .loginScreen__box__s1__cover > img {
    width: 100%;
    padding: 40px 0;
  }

  .loginScreen__box__s2__iframe {
    display: none;
  }

  .loginScreen__box__s2__content {
    width: auto;
    margin: 0 auto;
    padding: 0 20px
  }

  .loginScreen__box__s2__content > h3 {
    width: auto;
    font-size: 1.4rem;
  }

  .loginScreen__box__s2__content > p {
    width: auto;
    font-size: 1.4rem;
  }

  .loginScreen__box__s3 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 40px 0;
  }

  .loginScreen__box__s3 > img {
    width: 100%;
    padding: 0 20px;
    transition: transform 450ms;
  }

  .loginScreen__box__s3 > img:hover {
    transform: scale(1.4);
  }

  .loginScreen__box__s4 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  flex-direction: column;
  }

  .loginScreen__box__s4 > div {
    width: auto;
    padding: 20px 0 40px 0;
    flex-direction: column;
  }

  .loginScreen__box__s4 div > img {
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin: 0;
  }

  .loginScreen__box__s4 div > p {
    width: 100%;
    height: auto;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 0;
  }

  .loginScreen__box__s5 {
    width: 80%;
    padding: 40px 20px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .loginScreen__box__shop__content {
    padding-top: 80px;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__content > h2 {
    font-size: 1.8rem;
    padding: 20px 0;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__content > h4 {
    font-size: 1.5rem;
    padding-bottom: 20px;
  }

  .loginScreen__box__shop__content > p {
    font-size: 1.2rem;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__img img {
    display: none;
  }
}

/* Laptop */
@media (min-width: 1000px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}
