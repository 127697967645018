.profile {
  color: white;
  margin: 0;
}

.profile__container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  width: auto;
  justify-content: center;
}

.profile__data {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}

.profile__data__img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile__data__img img {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  justify-content: center;
  margin: 10px auto;
}

.profile__data__img > input {
  border-radius: 2.5px;
}

.profile__data__img button {
  width: 250px;
  padding: 20px;
  margin: 20px 0;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 10px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.profile__data__img > button:hover {
  background-color: #2556cc;
}

.profile__data form > ul {
  padding: 20px 0;
}

.profile__data form ul > li {
  padding: 10px 0;
  display: flex;
}

.profile__data form {
  padding-top: 20px;
}

.profile__data form ul li > p {
  padding-right: 5px;
}

.profile__data form ul li > input {
  padding: 7px;
  margin: 0 5px;
  height: 12px;
  border: none;
  border-radius: 5px;
}

.profile__data form ul li > input::placeholder {
  color: #111;
}

.profile__data__updated {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.profile__data__updated ul {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.profile__data__updated ul li {
  display: flex;
  justify-content: center;
  width: auto;
}

.profile__data__updated ul button {
  display: flex;
  width: 250px;
  padding: 20px;
  margin: 20px auto;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 10px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  justify-content: center;
}

.profile__data__updated ul > button:hover {
  background-color: #2556cc;
}

input {
  color: #111;
}

.profile__interestingvideos {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__sub__data {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: center;
  margin: 0 auto;
}

.profile__sub__data ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.profile__sub__data ul li {
  padding: 10px 0;
}

.profile__sub__data ul li > p {
  font-size: 1.2rem;
  font-weight: 200;
}

.profile__sub__data ul li p > span {
  font-weight: 400;
  color: var(--teleboing-two);
}

.profile__sub__cancel {
  display: flex;
  width: 250px;
  padding: 20px;
  margin: 40px auto;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--teleboing-two);
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  justify-content: center;
}

/* CSS for the tab structure */
.tabs-container {
  width: 100%;
}

.tabs-header {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  width: 100%;
  border-radius: 20px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.tabs-header::-webkit-scrollbar {
  display: none;
}

.tab {
  background-color: transparent;
  border: solid 2px var(--teleboing-two);
  border-radius: 20px;
  padding: 15px 30px;
  cursor: pointer;
  width: auto;
  font-size: 1.2rem;
}

.tab.active {
  background-color: var(--teleboing-two);
  color: var(--teleboing-zero);
}


/* Phones and Tablet*/
@media (max-width: 799px) {
  .tabs-header {
    display: flex;
    justify-content: left;
    padding: 20px 10px;
    width: 100%;
    border-radius: 20px;
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

/* Tablet and small laptop */
@media (min-width: 800px) {
}

/* Laptop */
@media (min-width: 900px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
