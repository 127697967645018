.nav {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 0px 20px 0px; */
  /* height: 45px; */
  z-index: 1000;
  color: white;
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
  height: auto;
  padding: 10px 0;
  margin: 0;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
}

.nav__contents {
  display: flex;
  margin: 0 40px;
  padding: 5px;
  justify-content: space-between;
}

.nav__logo img {
  height: fit-content;
  width: 120px;
  /* padding-top: 5px; */
  /* padding-right: 20px; */
  cursor: pointer;
  align-items: center;
}

/* .nav__search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid lightgray;
  height: 13px;
  padding: 10px 10px;
  border-radius: 999px;
  width: 75vw;
  margin: 0 auto;
  max-width: 250px;
  color: whitesmoke;
}

.nav__search:hover {
  background-color: lightgray;
  opacity: 1;
  transition: 1s;
  color: #111;
}

.nav__search form {
  display: flex;
}

.nav__search form > input {
  flex: 1;
  padding: 0px 10px;
  font-size: medium;
  border: none;
  background: none;
  color: whitesmoke;
}

.nav__search form > input:hover {
  color: black;
}

.nav__search form > input:focus {
  outline-width: 0;
}

::-webkit-input-placeholder {
  opacity: 0.75;
}

.nav__searchButton {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  opacity: 0.75;
  padding-top: 5px;
}

.nav__searchButton:focus {
  outline: none;
} */

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  font-weight: bold;
}

:-ms-input-placeholder {
  color: white;
  font-weight: lighter;
}

.nav__icons {
  color: white;
  list-style: none;
  padding: 0 40px;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav__icon > .MuiSvgIcon-root {
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon:hover > .MuiSvgIcon-root {
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 75px;
}

.nav__icon > p {
  font-size: small;
  display: none;
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  text-align: center;
}

.nav__icon--active > p {
  display: inline-flex !important;
}

.nav__icon:hover > p {
  display: inline-flex;
}

.nav__profile {
  display: flex;
  right: 0;
  padding-right: 0px;
  cursor: pointer;
}

.nav__profile > p {
  margin: auto;
  align-items: center;
  justify-content: center;
}

.nav__profile__block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.nav__avatar {
  padding: 0 15px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.nav__profile__dropdown {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .nav {
    position: sticky;
    top: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    height: auto;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 0.7);

    /* Animaciones */
    transition-timing-function: ease-in;
    transition: all 0.5s;
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__contents {
    margin: 0;
    top: 0;
    display: grid;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  .nav__logo {
    margin-left: 0px;
    width: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .nav__icons {
    display: none;
  }
  .nav__profile {
    display: none;
  }
  .nav__search {
    display: grid;
    justify-content: center;
    align-items: center;
    border: 2px solid lightgray;
    height: 13px;
    padding: 10px 10px;
    border-radius: 999px;
    max-width: 250px;
    color: whitesmoke;
  }

  .nav__search:hover {
    background-color: lightgray;
    opacity: 1;
    transition: 1s;
    color: #111;
  }

  .nav__search form {
    display: inline-flex;
  }

  .nav__search form > input {
    flex: 1;
    padding: 0px 20px;
    font-size: small;
    border: none;
    background: none;
    color: whitesmoke;
  }

  .nav__search form > input:hover {
    color: black;
  }

  .nav__search form > input:focus {
    outline-width: 0;
  }

  ::-webkit-input-placeholder {
    opacity: 0.75;
  }

  .nav__searchButton {
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    opacity: 0.75;
    display: none;
  }

  .nav__searchButton:focus {
    outline: none;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: white;
    font-weight: bold;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
    font-weight: bold;
  }

  :-ms-input-placeholder {
    color: white;
    font-weight: lighter;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .nav__icons {
    display: none;
  }
  .nav__profile {
    display: none;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .nav__icons {
    display: none;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}
