.recommendedVideos {
  top: 0;
}

.recommendedVideos__msg {
  align-items: center;
  justify-content: center;
  display: flex;
}

.recommendedVideos__msg > h2 {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 40px 0;
}

.recommendedVideos__msg h2 > span {
  font-size: 1.8rem;
  font-weight: 600;
}

.recommendedVideos__videocard > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .recommendedVideos {
    display: block;
    flex-direction: column;
  }
  .recommendedVideos .recommendedVideos__msg {
    padding: 20px 0;
    text-align: center;
  }
  .recommendedVideos .recommendedVideos__videocard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .recommendedVideos {
    display: flex;
    flex-direction: column;
  }
  .recommendedVideos .recommendedVideos__msg {
    padding: 20px 0;
    text-align: center;
  }
  .recommendedVideos .recommendedVideos__videocard {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  } */
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
