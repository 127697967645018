.originals__update {
  color: white;
  padding-top: 20px;
  padding-bottom: 60px;
}

.originals__update__container {
  display: flex;
  justify-content: space-between;
}

.originals__update__title {
  padding-left: 40px;
  padding-bottom: 20px;
}

.originals__update__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
}

.originals__update__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
  text-align: center;
}

.originals__update__posters::-webkit-scrollbar {
  display: none;
}

.originals__update__poster {
  object-fit: contain;
  max-height: 250px;
  cursor: pointer;
}

.originals__update__poster__props {
  object-fit: contain;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  transition: transform 450ms;
}

.originals__update__poster__props:hover {
  transform: scale(1.07);
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .originals__update__explora {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .originals__update__explora {
    display: none;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
