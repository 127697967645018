@import url(https://fonts.googleapis.com/css?family=Rubik);
* {
  padding: 0;
  margin: 0;
  font-family: "Product Sans", sans-serif;
}

body, html {
  --teleboing-zero: white;
  --teleboing-one: #111/* #680080 */;
  --teleboing-two: #ED244E/* #530066 */;
  --teleboing-three: #8E152E/* #3f004d */;
  --teleboing-four: #5E0E1F;
  --teleboing-five: #2F070F;
  --teleboing-six: #886f1d/* #2a0034 */;
  --teleboing-seven:#5e4d14/* #ED244E */;
  --teleboing-eight: #342b0b/* #9200b3 */;
  --teleboing-nine: #1f1907/* #15001a */;
  color: white;
  background-image: linear-gradient(to right, #111, black);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to right, black, black); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorScreen {
    color: white;
    height: 100vh;
    width: 100%;
}

.errorScreen__content {
    display: flex;
    margin: 0;
}

.errorScreen__msg {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 40px;
    width: 50%;
    margin: auto 0;
    align-items: center;
}

.errorScreen__msg__t1 {
    font-size: 3rem;
    padding: 20px 0;
    text-align: center;
    font-weight: 600;
    color: var(--teleboing-three);
}

.errorScreen__msg__t2 {
    opacity: 0.8;
    font-size: 1.4rem;
    text-align: center;
    color: var(--teleboing-three);
}

.errorScreen__msg__t3 {
    padding: 20px 0;
    text-align: center;
    font-size: 1.8rem;
    color: var(--teleboing-three);
}

.errorScreen__msg__t4 {
    padding: 10px 0 20px 0;
    text-align: center;
    font-size: 1rem;
    color: var(--teleboing-three);
}

.errorScreen__logo {
    display: flex;
    width: 200px;
    padding: 60px 0;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.errorScreen__msg button {
    border: solid 2px var(--teleboing-three);
    background-color: var(--teleboing-four);
    color: var(--teleboing-zero);
    font-size: 1.2rem;
    font-weight: 400;
    padding: 20px;
    border-radius: 20px;
    margin: 5px 0;
    cursor: pointer;
    transition: all 0.5s ease;
}

.errorScreen__msg button:hover {
    background-color: var(--teleboing-three);
    transition: all 0.5s ease;
}

.errorScreen__logo img {
    margin: 0 auto;
}
.copyright {
  margin: 0;
  color: darkgrey;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  font-size: 0.9rem;
}

.copyright > p {
  padding: 5px;
  text-align: center;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif, Lighter, Light, Regular, Medium, Bold;
  /* font-family: "Asap Condensed", sans-serif; */
  /* font-family: "Trebuchet MS", sans-serif; */
}

.app {
  /* background-color: #111; */
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(to right, black, #111);
  z-index: -1; */
}

* {
  margin: 0;
}

/* .homeScreen {
  background-color: #111;
} */

.rowcortotendencia {
  color: white;
  padding-top: 40px;
}

.rowcortotendencia__container > h2 {
  padding-left: 40px;
  padding-bottom: 40px;
}

.rowcortotendencia__container {
  display: flex;
  /* justify-content: space-between; */
}

/* .rowcortotendencia__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
} */

.rowcortotendencia__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.rowcortotendencia__posters::-webkit-scrollbar {
  display: none;
}

.rowcortotendencia__poster {
  max-height: 400px;
  border-radius: 0px;
  padding-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.rowcortotendencia__poster__props {
  height: 480px;
  /* height: 550px; */
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
  padding-left: 20px;
}

.rowcortotendencia__poster__props:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.rowcortotendencia__poster__title {
  max-width: 250px;
  padding-left: 5px;
  padding-top: 20px;
  align-items: center;
}

.rowcortotendencia__poster__details {
  display: none;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__title {
  display: none;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__details {
  display: none;
  /* display: inline; */
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__title {
  display: inline;
  transition: all ease 1.5s;
}

.rowcortotendencia__poster__description {
  font-size: 0.9rem;
  font-weight: lighter;
  padding-left: 5px;
  padding-top: 10px;
  max-width: 250px;
  opacity: 0.7;
}

/* .rowcortotendencia__poster__stats {
  padding-left: 20px;
} */

.rowcortotendencia__poster__buttons {
  display: flex;
}

/* .rowcortotendencia__posterLarge {
  max-height: 250px;
}

.rowcortotendencia__posterLarge:hover {
  transform: scale(1.09);
} */

.rowcortotendencia__poster__left,
.rowcortotendencia__poster__right {
  position: absolute;
  width: 40px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding: 30px 0;
  margin-top: 40px;
}

.rowcortotendencia__poster__left {
  left: 0;
}

.rowcortotendencia__poster__right {
  right: 0;
}

.rowcortotendencia:hover .rowcortotendencia__poster__left,
.rowcortotendencia:hover .rowcortotendencia__poster__right {
  opacity: 1;
}

@media (max-width: 760px) {
  .rowcortotendencia__poster__left,
  .rowcortotendencia__poster__right {
    opacity: 1;
  }
}

.rowcortotendencia__player {
  padding-bottom: 40px;
}

/* Phones and Tablet*/
@media (max-width: 799px) {
  .rowcortotendencia__container > h2 {
    padding-left: 0;
    text-align: center;
    justify-content: center;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .rowcortotendencia__poster__props {
    height: auto;
  }

  .rowcortotendencia__poster__title {
    display: none;
  }

  .rowcortotendencia__poster__details {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__title {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__props:hover
    > .rowcortotendencia__poster__details {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__props:hover > .rowcortotendencia__poster__title {
    display: none;
    transition: all ease 1.5s;
  }

  .rowcortotendencia__poster__description {
    display: none;
  }

  .rowcortotendencia__poster__left,
.rowcortotendencia__poster__right {
  display: none;
}
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.postSk {
  width: 274px;
  margin-right: 12px;
  margin-bottom: 30px;
  -webkit-animation: skeleton 1s ease infinite alternate;
          animation: skeleton 1s ease infinite alternate;
}

@-webkit-keyframes skeleton {
  to {
    opacity: 0.5;
  }
}

@keyframes skeleton {
  to {
    opacity: 0.5;
  }
}

.postSkImg {
  background-color: #313131;
  width: 274px;
  height: 153px;
}

.postSkInfo {
  display: flex;
  margin-top: 15px;
}

.postSkAvatar {
  background-color: #313131;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.postSkDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.postSkText {
  background-color: #313131;
  width: 90%;
  height: 20px;
  margin-bottom: 5px;
}

.postSkText.sm {
  width: 50%;
}

.topSk {
  flex: 3 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-animation: skeleton 1s ease infinite alternate;
          animation: skeleton 1s ease infinite alternate;
}

.topSkIcon {
  margin-right: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #313131;
}

.topSkImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 40px;
  background-color: #313131;
}

.menuSk {
  -webkit-animation: skeleton 1s ease infinite alternate;
          animation: skeleton 1s ease infinite alternate;
}

.menuSkItem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #313131;
  margin-top: 40px;
}

.circle,
.custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom {
  flex-direction: column;
}

.balls {
  display: flex;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  -webkit-animation: ball 0.5s ease infinite alternate;
          animation: ball 0.5s ease infinite alternate;
}

.ball2 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.ball3 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

@-webkit-keyframes ball {
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes ball {
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

.customTet {
  font-weight: 100;
}

.rowcortoestreno {
  color: white;
  padding-top: 30px;
}

.rowcortoestreno__container > h2 {
  padding-left: 40px;
  padding-bottom: 30px;
}

.rowcortoestreno__container {
  display: flex;
  /* justify-content: space-between; */
}

/* .rowcortoestreno__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
} */

.rowcortoestreno__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.rowcortoestreno__posters::-webkit-scrollbar {
  display: none;
}

.rowcortoestreno__poster {
  max-height: 250px;
  border-radius: 0px;
  padding-right: 20px;
  cursor: pointer;
}

.rowcortoestreno__poster__props {
  height: 450px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
  padding-left: 20px;
}

.rowcortoestreno__poster__props:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.rowcortoestreno__poster__title {
  max-width: 300px;
  padding-left: 5px;
  padding-top: 0px;
  align-items: center;
}

.rowcortoestreno__poster__details {
  /* display: none; */
  transition: all ease 1.5s;
}

.rowcortoestreno__poster__title {
  /* display: none; */
  transition: all ease 1.5s;
}

/* .rowcortoestreno__poster__props:hover > .rowcortoestreno__poster__details {
  display: inline;
  transition: all ease 1.5s;
}

.rowcortoestreno__poster__props:hover > .rowcortoestreno__poster__title {
  display: inline;
  transition: all ease 1.5s;
} */

.rowcortoestreno__poster__description {
  font-size: 0.9rem;
  font-weight: lighter;
  padding-left: 5px;
  padding-top: 10px;
  max-width: 300px;
  opacity: 0.7;
}

.rowcortoestreno__poster__stats {
  padding-left: 20px;
}

/* .rowcortoestreno__posterLarge {
  max-height: 250px;
}

.rowcortoestreno__posterLarge:hover {
  transform: scale(1.09);
} */

.rowcortoestreno__poster__left,
.rowcortoestreno__poster__right {
  position: absolute;
  width: 40px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding: 30px 0;
}

.rowcortoestreno__poster__left {
  left: 0;
}

.rowcortoestreno__poster__right {
  right: 0;
}

.rowcortoestreno:hover .rowcortoestreno__poster__left,
.rowcortoestreno:hover .rowcortoestreno__poster__right {
  opacity: 1;
}

@media (max-width: 760px) {
  .rowcortoestreno__poster__left,
  .rowcortoestreno__poster__right {
    opacity: 1;
  }
}

/* .rowcortoestreno__player {
  padding-bottom: 40px;
} */

/* Phones and Tablet*/
@media (max-width: 799px) {
  .rowcortoestreno__container > h2 {
    padding-left: 0;
    text-align: center;
    justify-content: center;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .rowcortoestreno__poster__props {
    height: auto;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
    padding-left: 20px;
  }

  .rowcortoestreno__poster__left,
.rowcortoestreno__poster__right {
  display: none;
}
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.banner__home {
  position: relative;
  height: 100vh;
  width: auto;
  color: #fff;
  padding-bottom: 70px;
  /* object-fit: contain; */
}

.banner__frame {
  display: flex;
  justify-content: left;
  left: 0;
  /* object-fit: contain; */
}

.banner__youtube {
  padding-bottom: 40px;
}

.banner__contents {
  margin: 0;
  padding: 50px 0 0 40px;
  height: 75vh;
  width: 40%;
}

.banner__live {
  font-size: 1.2rem;
  font-weight: 400;
  width: auto;
  color: red;
  padding-bottom: 20px;
}

.banner__pretitle {
  font-size: 1.2rem;
  font-weight: 400;
  width: auto;
  opacity: 0.7;
}

.banner__title {
  font-size: 3rem;
  padding: 20px 0;
  max-width: auto;
  font-weight: 600;
}

.banner__subtitle1 {
  font-size: 1.2rem;
  padding-bottom: 10px;
  line-height: 1.7rem;
}

.banner__subtitle1 > span {
  opacity: 0.7;
  padding-right: 10px;
}

.banner__subtitle2 {
  font-size: 1.2rem;
  padding-bottom: 20px;
  line-height: 1.7rem;
}

.banner__subtitle2 > span {
  opacity: 0.7;
  padding-right: 10px;
}

.banner__description {
  line-height: 1.5;
  font-size: 1rem;
  max-width: auto;
  font-weight: 200;
  opacity: 0.7;
}

/* .banner__timestamp {
  padding: 10px 0;
  font-size: 1rem;
  max-width: auto;
} */

/* .banner__author {
  font-size: 3rem;
  font-weight: 800;
  width: auto;
  padding: 20px 0;
} */

.banner__buttons {
  display: flex;
  width: 100%;
  padding-top: 30px;
}

.banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
  align-items: center;
}

.banner__button:hover {
  color: #000;
  font-weight: 700;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .banner__home {
    position: relative;
    color: #fff;
    padding-bottom: 70px;
  }

  .banner__frame {
    display: flex;
  }

  .banner__contents {
    margin: 0;
    padding: 20px 40px 0 40px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .banner__live {
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
    color: red;
    padding-bottom: 20px;
  }

  .banner__pretitle {
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
    opacity: 0.7;
  }

  .banner__title {
    display: flex;
    font-size: 3rem;
    padding: 20px;
    width: auto;
    font-weight: 600;
    margin: 0;
    justify-content: center;
    text-align: center;
  }

  .banner__subtitle1 {
    font-size: 1.2rem;
    padding: 10px 20px;
    line-height: 1.7rem;
  }

  .banner__subtitle1 > span {
    opacity: 0.7;
    padding-right: 0;
  }

  .banner__subtitle2 {
    font-size: 1.2rem;
    padding-bottom: 0 20px 20px 20px;
    line-height: 1.7rem;
  }

  .banner__subtitle2 > span {
    opacity: 0.7;
    padding-right: 0;
  }

  .banner__description {
    display: none;
  }

  .banner__buttons {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: -20px;
  }

  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 0.8vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 10px 0;
    padding-top: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
    align-items: center;
    font-size: 1.2rem;
  }

  .banner__youtube {
    padding-bottom: 40px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .banner__contents {
    margin: 0;
    padding: 20px 0 0 40px;
    height: auto;
    width: 70%;
  }
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;

    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
  }
  .banner__description {
    display: none;
  }
  .banner__timestamp {
    display: none;
  } */
}

/* Laptop */
@media (max-width: 992px) {
  /* .banner__contents {
    margin: 0;
    padding: 20px 0 0 40px;
    height: auto;
    width: 70%;
  }
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    border-radius: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 1rem;
  } */
}

/* Desktop */
@media (max-width: 1200px) {
}

.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 0px 20px 0px; */
  /* height: 45px; */
  z-index: 1000;
  color: white;
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
  height: auto;
  padding: 10px 0;
  margin: 0;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
}

.nav__contents {
  display: flex;
  margin: 0 40px;
  padding: 5px;
  justify-content: space-between;
}

.nav__logo img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 120px;
  /* padding-top: 5px; */
  /* padding-right: 20px; */
  cursor: pointer;
  align-items: center;
}

/* .nav__search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid lightgray;
  height: 13px;
  padding: 10px 10px;
  border-radius: 999px;
  width: 75vw;
  margin: 0 auto;
  max-width: 250px;
  color: whitesmoke;
}

.nav__search:hover {
  background-color: lightgray;
  opacity: 1;
  transition: 1s;
  color: #111;
}

.nav__search form {
  display: flex;
}

.nav__search form > input {
  flex: 1;
  padding: 0px 10px;
  font-size: medium;
  border: none;
  background: none;
  color: whitesmoke;
}

.nav__search form > input:hover {
  color: black;
}

.nav__search form > input:focus {
  outline-width: 0;
}

::-webkit-input-placeholder {
  opacity: 0.75;
}

.nav__searchButton {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  opacity: 0.75;
  padding-top: 5px;
}

.nav__searchButton:focus {
  outline: none;
} */

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  font-weight: bold;
}

:-ms-input-placeholder {
  color: white;
  font-weight: lighter;
}

.nav__icons {
  color: white;
  list-style: none;
  padding: 0 40px;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav__icon > .MuiSvgIcon-root {
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon:hover > .MuiSvgIcon-root {
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 75px;
}

.nav__icon > p {
  font-size: small;
  display: none;
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  text-align: center;
}

.nav__icon--active > p {
  display: inline-flex !important;
}

.nav__icon:hover > p {
  display: inline-flex;
}

.nav__profile {
  display: flex;
  right: 0;
  padding-right: 0px;
  cursor: pointer;
}

.nav__profile > p {
  margin: auto;
  align-items: center;
  justify-content: center;
}

.nav__profile__block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.nav__avatar {
  padding: 0 15px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.nav__profile__dropdown {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    height: auto;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 0.7);

    /* Animaciones */
    transition-timing-function: ease-in;
    transition: all 0.5s;
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__contents {
    margin: 0;
    top: 0;
    display: grid;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  .nav__logo {
    margin-left: 0px;
    width: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .nav__icons {
    display: none;
  }
  .nav__profile {
    display: none;
  }
  .nav__search {
    display: grid;
    justify-content: center;
    align-items: center;
    border: 2px solid lightgray;
    height: 13px;
    padding: 10px 10px;
    border-radius: 999px;
    max-width: 250px;
    color: whitesmoke;
  }

  .nav__search:hover {
    background-color: lightgray;
    opacity: 1;
    transition: 1s;
    color: #111;
  }

  .nav__search form {
    display: inline-flex;
  }

  .nav__search form > input {
    flex: 1 1;
    padding: 0px 20px;
    font-size: small;
    border: none;
    background: none;
    color: whitesmoke;
  }

  .nav__search form > input:hover {
    color: black;
  }

  .nav__search form > input:focus {
    outline-width: 0;
  }

  ::-webkit-input-placeholder {
    opacity: 0.75;
  }

  .nav__searchButton {
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    opacity: 0.75;
    display: none;
  }

  .nav__searchButton:focus {
    outline: none;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: white;
    font-weight: bold;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
    font-weight: bold;
  }

  :-ms-input-placeholder {
    color: white;
    font-weight: lighter;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .nav__icons {
    display: none;
  }
  .nav__profile {
    display: none;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .nav__icons {
    display: none;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}

:root {
  --bg: #2580CC;
  /* --bg: #595449; */
  --bg-accent: black;
  --text-color: #dadce1;
  --nav-size: 35px;
  --border: 1px solid #2580CC;
  --border-radius: 20px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #dadce1;
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: 35px;
  height: var(--nav-size);
  background-color: #2580CC;
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: 1px solid #2580CC;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(35px * 0.8);
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.8);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #2580CC;
  border: none;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.icon-button svg {
  fill: #dadce1;
  fill: var(--text-color);
  width: 25px;
  height: 25px;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 80px;
  width: 200px;
  -webkit-transform: translateX(-45%);
          transform: translateX(-45%);
  background-color: #2580CC;
  background-color: var(--bg);
  border: 1px solid #2580CC;
  border: var(--border);
  border-radius: 20px;
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border-radius: var(--border-radius);
  transition: background 500ms;
  transition: background var(--speed);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.menu-item .icon-button {
  margin-right: 0.7rem;
}

.menu-item .icon-button:hover {
  -webkit-filter: none;
          filter: none;
}

.menu-item:hover {
  /* background-color: #525357; */
  /* background-color: cornflowerblue; */
  background-color: #111;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}
.menu-primary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}
.menu-secondary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}

.originals {
  color: white;
  padding: 20px 0 100px 0;
}

.originals__container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.originals__title {
  padding-left: 40px;
  padding-bottom: 0px;
}

.originals__explora {
  opacity: 0.2;
  padding-right: 20px;
}

.originals__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px 20px 20px 40px;

  width: auto;
  text-align: center;
}

.originals__posters::-webkit-scrollbar {
  display: none;
}

.originals__poster {
  object-fit: contain;
  max-height: 450px;
  cursor: pointer;
  border-radius: 0px;
}

.originals__poster__props {
  object-fit: contain;
  width: 100%;
  margin-right: 10px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}

.originals__poster__props:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .originals__explora {
    display: none;
  }

  .originals__posters {
    margin: 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .originals__explora {
    display: none;
  }

  .originals__posters {
    margin: 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .originals__posters {
    margin: 0;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .originals__posters {
    margin: 0;
  }
}

.linksList {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.link {
  flex-basis: 25%;
  padding: 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.linklist span > a {
  color: #808080;
  text-decoration: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .linksList {
    display: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .linksList > span {
    padding: 10px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .linksList {
    display: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .linksList > span {
    padding: 10px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.iconLink {
  margin: 0 1rem;
}

.navbottom__icons {
  color: white;
  margin: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  display: flex;
  height: 60px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: rgba(17, 17, 17, 0.95);
  overflow-y: hidden;
  overflow-x: auto;
}

.navbottom__icon {
  flex-grow: 1;
  text-align: center;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
}

.navbottom__icon:hover {
  color: orange;
  opacity: 0.5;
  transition: 0.5s;
}

.navbottom__icon--active {
  color: orange;
}

.navbottom__icon-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .navbottom__icons::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .navbottom__icons {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (min-width: 992px) {
  .navbottom__icons {
    display: none;
  }
  .navbottom__icon {
    display: none;
  }
  .navbottom__icon--active {
    display: none;
  }
  .navbottom__icon-content {
    display: none;
  }
}

/* Desktop */
@media (min-width: 1200px) {
  .navbottom__icons {
    display: none;
  }
  .navbottom__icon {
    display: none;
  }
  .navbottom__icon--active {
    display: none;
  }
  .navbottom__icon-content {
    display: none;
  }
}

.loginScreen {
  margin: 0;
  padding: 0;
  color: white;
}

.loginScreen__box__signup {
  margin: 0;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.loginScreen__logo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 120px;
  padding: 0 20px;
  cursor: pointer;
}

.loginScreen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginScreen__signup form {
  display: flex;
  text-align: center;
}

.loginScreen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 2px solid var(--teleboing-three);
  background-color: transparent;
  cursor: pointer;
  color: var(--teleboing-zero); 
  border-radius: 50px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.loginScreen__signup form > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
  color: var(--teleboing-zero); 
  transition: all 0.7s ease;
}

.loginScreen__box__cover {
  color: white;
}

.loginScreen__cover__contents {
  font-size: 1.2rem;
  max-width: 450px;
  height: 70vh;
  padding: 100px 0 0 100px;
  z-index: 99;
}

.loginScreen__cover__contents > h1 {
  padding: 20px 0;
  z-index: 99;
}

.loginScreen__cover__contents > p {
  padding-bottom: 15px;
  font-size: 1.5rem;
  z-index: 99;
}

.loginScreen__cover__contents img {
  width: 25%;
  padding-top: 10px;
}

.loginScreen__box__s1__content {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 0;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.loginScreen__box__s1__content > h1 {
  padding: 0;
  font-size: 2rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  
}

.loginScreen__box__s1__content > p {
  padding: 20px 0;
  font-size: 1.4rem;
  line-height: 2rem;
}

.loginScreen__box__s1__content p > span {
  font-size: 0.8rem;
}

.loginScreen__box__s1__content > span {
  font-size: 1.4rem;
  padding-bottom: 20px;
  /* opacity: 0.7; */
  color: var(--teleboing-two);
  line-height: 2rem;
}

.loginScreen__box__s1__content > button {
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1.2rem;
  border-radius: 50px;
  border: 2px solid var(--teleboing-three);
  background-color: var(--teleboing-one);
  color: var(--teleboing-zero);
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__s1__content > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
}

.loginScreen__box__s1__cover > img {
  width: 100%;
  padding: 20px 0;
}

.loginScreen__box__s2 {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: flex-start;
  padding: 40px 0 20px 0;
  flex-direction: column;
}

.loginScreen__box__s2__content {
  padding: 20px 0;
  max-width: 900px;
}

.loginScreen__box__s2__content > h2 {
  font-size: 2rem;
  padding: 20px 0;
  line-height: 2.8rem;
  color: var(--teleboing-two);
  opacity: 0.9;
}

.loginScreen__box__s2__content > p {
  font-size: 1.4rem;
  line-height: 2rem;
}

.loginScreen__box__s2__iframe {
  padding: 0 40px;
}

.loginScreen__box__s2__iframeresponsive {
  width: 100%;
  height: 100%;
}

.loginScreen__box__s4 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 0 20px 0;
  flex-direction: column;
}

.loginScreen__box__s4 > div {
  width: auto;
  padding: 40px 120px;
  flex-direction: column;
}

.loginScreen__box__s4 div > h3 {
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  text-align: center;
  padding: 20px 0;
}

.loginScreen__box__s5 {
  width: 80%;
  padding: 100px 80px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.loginScreen__box__s5 > h3 {
  padding-bottom: 40px;
  text-align: center;
}

/*.react-tabs--selected {
  background: var(--teleboing-three);
  border-color: var(--teleboing-three);
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px var(--teleboing-three);
  border-color: var(--teleboing-three);
  outline: none;
} */

.loginScreen__box__s6__content {
  margin: 40px 0 80px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loginScreen__box__s6__content > p {
  padding: 20px 0;
  font-size: 1.4rem;
}

.loginScreen__box__s6__content > button {
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1.2rem;
  border-radius: 50px;
  border: 2px solid var(--teleboing-three);
  background-color: transparent;
  color: var(--teleboing-zero); 
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__s6__content > button:hover {
  background-color: var(--teleboing-three);
  border: 2px solid var(--teleboing-three);
}

/* .loginScreen__box__shop {
  width: 100%;
  height: 100%;
  color: white;
  margin: 0 0 80px 0;
}

.loginScreen__box__shop__container {
  margin: 0;
  align-items: center;
}

.loginScreen__box__shop__content {
  padding: 60px 0;
  width: 40%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__content > h2 {
  font-size: 2.2rem;
  padding: 20px 0;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__content > h4 {
  font-size: 1.8rem;
  padding-bottom: 20px;
}

.loginScreen__box__shop__content > p {
  font-size: 1.5rem;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.loginScreen__box__shop__img {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.loginScreen__box__shop__img > img {
  width: 15%;
  padding: 0 10px;
  transition: transform 450ms;
  border-radius: 40px;
}

.loginScreen__box__shop__img > img:hover {
  transform: scale(1.25);
}

.loginScreen__box__shop__content > button {
  padding: 15px 20px;
  margin: 25px 0;
  font-size: 1rem;
  color: #fff;
  border-radius: 10px;
  background-color: #111;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.loginScreen__box__shop__content > button:hover {
  background-color: black;
  transition: all 0.5s ease;
} */

/* Phones and Tablet*/

@media (max-width: 899px) {
}

/* Tablet and small laptop */
@media (max-width: 999px) {
  .loginScreen__headline .loginScreen__headline__usersconnected {
    display: none;
  }
  .loginScreen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .loginScreen__logo {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 120px;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .loginScreen__signup {
    display: none;
    color: #fff;
    /* display: grid; */
    padding: 10px;
  }

  .loginScreen__box__divcover {
    display: none;
  }

  .loginScreen__box__cover {
    display: none;
  }

  .loginScreen__cover__contents {
    display: none;
  }

  .loginScreen__box__s1__content {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    width: auto;
    display: flex;
    flex-direction: column;
  }

  .loginScreen__box__s1__content > h2 {
    padding: 10px 0;
    font-size: 1.8rem;
  }

  .loginScreen__box__s1__content > p {
    padding: 20px 0;
    font-size: 1.4rem;
  }

  .loginScreen__box__s1__content > span {
    font-size: 1.4rem;
    padding: 10px 0;
  }

  .loginScreen__box__s1__cover > img {
    width: 100%;
    padding: 40px 0;
  }

  .loginScreen__box__s2__iframe {
    display: none;
  }

  .loginScreen__box__s2__content {
    width: auto;
    margin: 0 auto;
    padding: 0 20px
  }

  .loginScreen__box__s2__content > h3 {
    width: auto;
    font-size: 1.4rem;
  }

  .loginScreen__box__s2__content > p {
    width: auto;
    font-size: 1.4rem;
  }

  .loginScreen__box__s3 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 40px 0;
  }

  .loginScreen__box__s3 > img {
    width: 100%;
    padding: 0 20px;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
  }

  .loginScreen__box__s3 > img:hover {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }

  .loginScreen__box__s4 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  flex-direction: column;
  }

  .loginScreen__box__s4 > div {
    width: auto;
    padding: 20px 0 40px 0;
    flex-direction: column;
  }

  .loginScreen__box__s4 div > img {
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin: 0;
  }

  .loginScreen__box__s4 div > p {
    width: 100%;
    height: auto;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 0;
  }

  .loginScreen__box__s5 {
    width: 80%;
    padding: 40px 20px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .loginScreen__box__shop__content {
    padding-top: 80px;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__content > h2 {
    font-size: 1.8rem;
    padding: 20px 0;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__content > h4 {
    font-size: 1.5rem;
    padding-bottom: 20px;
  }

  .loginScreen__box__shop__content > p {
    font-size: 1.2rem;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .loginScreen__box__shop__img img {
    display: none;
  }
}

/* Laptop */
@media (min-width: 1000px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}

input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8.8px 0;
  background-color: var(--teleboing-nine);
  height: 8px;
  border-radius: 5px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--teleboing-nine);
  border-radius: 5px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  margin-top: -4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}

input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}

input[type=range]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--teleboing-zero);
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.05);
  border: solid 3px var(--teleboing-four);
}


.prebannerTvIntro {
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
}

.bannerTvIntro {
  position: relative;
  height: 100vh;
  color: var(--teleboing-zero);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
}

.react-player {
  background-size: cover;
  background-position: center center;
  position: absolute;
  object-fit: contain;
  z-index: 0;
  width: 100%;
  height: auto;
}

.noHover{
  pointer-events: none;
}

.bannerTvIntro__frame {
  display: flex;
  object-fit: contain;
  position: absolute;
  justify-content: center;
  width: 100%;
}

.bannerTvIntro__nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  height: auto;
  z-index: 1000;
  color: white;
  background-color: black;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
  border-bottom: solid 1px var(--teleboing-nine);
  width: 100%;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.bannerTvIntro__nav__black {
  background-color: rgba(17, 17, 17, 0);
}

.bannerTvIntro__nav__contents {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
}

.bannerTvIntro__logo {
  width: 120px;
  cursor: pointer;
}

.bannerTvIntro__nav__contents__right {
  display: flex;
}

.bannerTvIntro__nav__contents__right__accesos {
    display: flex;
}

.bannerTvIntro__nav__contents__right__ticker {
  height: auto;
  overflow: hidden;
  position: relative;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  font-weight: 400;
  font-family: Paytone One;
  display: flex;
  align-items: center;
  right: 10px;
  width: 450px;
  white-space: nowrap;
  text-overflow: clip;
  opacity: 0.9;
}

.bannerTvIntro__nav__contents__right__ticker p {
  position: absolute;
  font-family: Paytone One;
  width: auto;
  height: auto;
  margin: 0;
  font-weight: 400;
  text-align: left;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-animation: overflow-scroll1 100s linear infinite;
          animation: overflow-scroll1 100s linear infinite;
}

@-webkit-keyframes overflow-scroll1 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes overflow-scroll1 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.bannerTvIntro__nav__contents__right__ticker p > span {
  color: var(--teleboing-one);
  padding: 0 20px;
  font-size: 1rem;
  align-items: center;
  margin: 0;
}

.bannerTvIntro__mute {
  margin: 0 5px;
}

.bannerTvIntro__author {
  font-size: 3rem;
  font-weight: 800;
  width: auto;
  padding: 20px 0;
}

.bannerTvIntro__buttons {
  display: flex;
  width: 100%;
}

.bannerTvIntro__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
}

.bannerTvIntro__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

.bannerTvIntro__title {
  font-size: 1.2rem;
  padding: 20px 0;
  max-width: auto;
  font-weight: 600;
}

.bannerTvIntro__description {
  line-height: 1.3;
  font-size: 1rem;
  max-width: auto;
  font-weight: 400;
}

.bannerTvIntro__timestamp {
  display: flex;
  padding: 0 20px;
  font-size: 1rem;
  max-width: auto;
  align-items: center;
  margin: 0;
}

.bannerTvIntro__nav__logo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 150px;
  padding: 15px 20px 10px 20px;
  cursor: pointer;
}


.bannerTvIntro__fullscreen {
  cursor: pointer;
  margin: 0 10px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 20px;
}

.bannerTvIntro__volumen {
  cursor: pointer;
  margin: 0 10px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 22px;
}

.bannerTvIntro__volumendesktop {
  cursor: pointer;
  margin: auto 10px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center;
  display: flex;
  width: 24px;
}

.bannerTvIntro__menu__option {
  max-height: 80vh;
  overflow-y: auto;
  padding: 40px 0;
}

.bannerTvIntro__menu__option__username {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.bannerTvIntro__menu__option p {
  font-size: 0.8rem;
  font-weight: 200;
  padding: 40px 20px 20px 20px;
  opacity: 0.6;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.bannerTvIntro__menu__option__link {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
}

.bannerTvIntro__menu__option__link > a {
  display: flex;
  align-items: center;
  color: var(--teleboing-zero);
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  margin: auto auto;
}

.bannerTvIntro__menu__option__link > a:hover {
  color: var(--teleboing-one);
}

.bannerTvIntro__menu__icon__linkdom {
  display: flex;
  align-items: center;
  color: var(--teleboing-zero);
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  margin: auto auto;
  padding: 2px 0;
}

.bannerTvIntro__menu__icon__linkdom:hover {
  color: var(--teleboing-one);
}

.bannerTvIntro__menu__icon__img {
  padding: 0 10px;
  width: 25px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items: center; display: flex;
}

.bannerTvIntro__nav__contents__channels {
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.bannerTvIntro__nav__contents__channels__selection {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(17, 17, 17, 0.7);
  border-radius: 0 0 15px 15px;
  top: 40px;
  padding: 10px;
}

.bannerTvIntro__nav__contents__channels__selectionicon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  transition: all 0.5s ease;
}

.bannerTvIntro__nav__contents__channels__selectionicon:hover {
  transition: all 0.5s ease;
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}

.bannerTvIntro__nav__contents__channels__img {
  display: flex;
  align-items:center;
  margin: auto 0;
  padding: 0 20px;
}

.bannerTvIntro__channels {
  display: flex;
  cursor: pointer;
  padding: 0;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  align-items:center;
  margin: auto 0;
}

.bannerTvIntro__datavideo {
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0;
  border-top: solid 1px var(--teleboing-nine);
  border-radius: 50px 50px 0 0;
  width: auto;
  background-color: black;
  z-index: 1;
}

.bannerTvIntro__datavideo {
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0;
  border-top: solid 1px var(--teleboing-nine);
  border-radius: 50px 50px 0 0;
  width: auto;
  background-color: black;
  z-index: 1;
}

.bannerTvIntro__datavideo__author {
  flex: 0.2 1;
}

.bannerTvIntro__datavideo__author h2 {
  font-size: 1.4rem;
  font-family: Paytone One;
  font-weight: 200;
  text-transform: uppercase;
}

.bannerTvIntro__datavideo__info {
  display: flex;
  flex-direction: column;
  flex: 0.6 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}

.bannerTvIntro__datavideo__info__title h1 {
  font-size: 1.2rem;
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  padding-bottom: 10px;
}

.bannerTvIntro__datavideo__info__ticker {
  display: flex;
  align-items: center;
  margin: 0 20px;
  font-size: 1.2rem;
  width: 100%;
  justify-content: center;
}

.bannerTvIntro__ticker {
  height: auto;
  overflow: hidden;
  position: relative;
  color: var(--teleboing-zero);
  background-color: var(--teleboing-one);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 360px;
  white-space: nowrap;
  text-overflow: clip;
  padding: 10px 20px;
  border-radius: 20px;
}

.bannerTvIntro__ticker p {
  font-family: Poppins;
  width: auto;
  height: auto;
  margin: 0;
  font-weight: 400;
  text-align: left;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-animation: overflow-scroll2 150s linear infinite;
          animation: overflow-scroll2 150s linear infinite;
}

@-webkit-keyframes overflow-scroll2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes overflow-scroll2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.bannerTvIntro__progress {
  display: flex;
  padding-left: 10px;
  width: 100px;
}

.bannerTvIntro__progress__counter1 {
  font-size: 1rem;
  font-weight: 200;
  color: var(--teleboing-six);
  padding: 0 5px;
}

.bannerTvIntro__progress__counter2 {
  font-size: 1rem;
  font-weight: 200;
}

.bannerTvIntro__datavideo__category {
  flex: 0.2 1;
  width: 100%;
  display: flex;
}

.bannerTvIntro__datavideo__category p {
  font-size: 1rem;
  font-family: Paytone One;
  font-weight: 400;
  letter-spacing: 1px;
  border: solid 2px var(--teleboing-three);
  background-image: linear-gradient(to right, var(--teleboing-five) 0%, black 100%);
  border-radius: 50px; 
  padding: 10px 40px;
  color: var(--teleboing-zero);
  width: auto;
  text-transform: uppercase;
}

.bannerTvIntro__row {
  color: white;
  padding: 0 0 80px 0;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}

.bannerTvIntro__row__container > h2 {
  padding: 40px 20px;
  font-family: Poppins;
  font-weight: bolder;
  font-size: 1.4rem;
  opacity: 0.8;
  color: var(--teleboing-three);
}

.row__banner__container {
  display: flex;
}

.bannerTvIntro__nav__contents__iconos {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  /* border: solid 1px var(--teleboing-three); */
  border: none;
  /* border-radius: 20px; */
  /* background-color: var(--teleboing-six); */
}

.bannerTvIntro__row {
  display: flex;
  padding: 60px 20px;
}

.bannerTvIntro__row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  transition: all ease 0.5s;
}

.bannerTvIntro__row__posters::-webkit-scrollbar {
  display: none;
}

.bannerTvIntro__row__poster {
  max-height: 250px;
  border-radius: 12px;
  cursor: pointer;
  border-radius: 20px;
}

.bannerTvIntro__row__poster:hover {
  -webkit-transform:translateY(-50px) ;
          transform:translateY(-50px) ;
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__props {
  height: 100%;
}

.bannerTvIntro__row__poster__props__catselected {
  display: flex;
  font-size: 1rem;
  border: solid 2px var(--teleboing-two);
  background-color: var(--teleboing-two);
  width: auto;
  border-radius: 20px;
  padding: 10px 20px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.bannerTvIntro__row__poster__props__category {
  display: flex;
  font-size: 1rem;
  border: solid 2px var(--teleboing-three);
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: space-between;
  width: auto;
  border-radius: 20px;
  padding: 10px 20px;
}

.bannerTvIntro__row__poster__props__category:hover {
  cursor: pointer;
  background-color: var(--teleboing-three);
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__props__category img  {
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  padding: 0 20px;
}                            

.bannerTvIntro__row__poster__author {
  display: flex;
  max-width: 300px;
  padding-left: 20px;
  padding-top: 10px;
  opacity: 0.8;
}

.bannerTvIntro__row__poster__details {
  max-width: 280px;
  padding-left: 20px;
  padding-top: 10px;
}

.bannerTvIntro__row__poster__title {
  font-size: 1.2rem;
  font-weight: 400;
}

.bannerTvIntro__row__poster__title > span {
  font-size: 1rem;
  font-weight: 200;
  opacity: 0.8;
}

.bannerTvIntro__row__poster__left,
.bannerTvIntro__row__poster__right {
  position: absolute;
  width: 40px;
  height: 270px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.bannerTvIntro__row__poster__left {
  left: 0;
}

.bannerTvIntro__row__poster__right {
  right: 0;
}

.bannerTvIntro__row:hover .bannerTvIntro__row__poster__left,
.bannerTvIntro__row:hover .bannerTvIntro__row__poster__right {
  opacity: 1;
}

.bannerTvIntro__row__poster__more {
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
  padding: 20px;
  border: solid 2px var(--teleboing-three);
  border-radius: 20px;
  cursor: pointer;
}

.bannerTvIntro__row__poster__more:hover {
  border: solid 2px var(--teleboing-four);
  background-color: var(--teleboing-four);
  transition: all 0.5s ease;
}

.bannerTvIntro__row__poster__more > p {
  display: flex;
  align-items: center;
  margin: auto 0;
  font-size: 1.2rem;
  justify-content: left;
}

.bannerTvIntro__preload {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  /* justify-content: space-between; */
  align-items: center;
  margin: auto auto;
  background: #000000;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #111, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bannerTvIntro__preload__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 400px;
  padding: 0;
  border-radius: 50%;
}

.bannerTvIntro__preload__logo {
  display: flex;
  margin: 0 auto;
  /* height: fit-content; */
  width: 200px;
  padding: 40px 0;
  justify-content: center;
}

.volume-icon-container {
  display: flex;
  padding: 0 20px;
}

.volume-control {
  display: flex;
}

.volume-control__slider {
  display: flex;
  margin: auto 0;
  align-items: center;
}


/* Phones and Tablet*/
@media (max-width: 899px) {
  .bannerTvIntro {
  position: relative;
  height: 330px;
  color: var(--teleboing-zero);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
    display: none;
  }

  .bannerTvIntro__preload {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
  }
  
  .bannerTvIntro__preload__img {
    width: 100%;
    height: auto;
  }

  .bannerTvIntro__channels {
    padding: 0;
  }

  .bannerTvIntro__volumen {
    display: none;
  }

  .bannerTvIntro__nav__contents {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
  }

  .bannerTvIntro__nav__contents__channels {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  }

  .bannerTvIntro__nav__contents__channels__selection {
    padding-top: 20px;
    top: 50px;
  }

  .prebanner {
    width: 100%;
    height: auto;
  }

  .banner {
    height: 100vh;
  }

  .bannerTvIntro__frame {
    display: flex;  
    flex-direction: column;
  }

  .react-player {
    background-size: cover;
    background-position: center center;
    position: absolute;
    object-fit: contain;
    z-index: 0;
  }

  .bannerTvIntro__nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: auto;
    z-index: 1000;
    color: white;
    background-color: black;
    border: none;
    /* background-color: rgba(17, 17, 17, 0.97); */
    /* box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px; */
    width: auto;
  }
  
  .bannerTvIntro__nav__black {
    background-color: rgba(17, 17, 17, 0);
    width: auto;
  }

  .bannerTvIntro__dropdown {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .bannerTvIntro__nav__contents {
    display: flex;
    padding: 0;
    width: auto;
  }
  
  .bannerTvIntro__container__menu {
    display: flex;
    position: relative;
  }
  
  .bannerTvIntro__menu {
    display: flex;
    cursor: pointer;
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
    align-items: center;
    width: 25px;
  }

  .bannerTvIntro__menu-box {
    position: absolute;
    top: calc(100%);
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    /* border-radius: 0 0 50px 50px; */
  }
  
  .bannerTvIntro__close-icon {
    position: absolute;
    top: -1.5rem;
    right: 1rem;
    padding: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .bannerTvIntro__menu__close {
    width: 40px;
    padding: 40px 0;
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%);
  }

  .bannerTvIntro__mobile__signup {
    margin: 0;
    color: #fff;
    display: flex;
    padding: 40px 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .bannerTvIntro__mobile__signup form {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .bannerTvIntro__mobile__signup form > button {
    margin: 5px;
    padding: 15px 20px;
    border: 2px solid var(--teleboing-three);
    background-color: transparent;
    cursor: pointer;
    color: var(--teleboing-zero); 
    border-radius: 50px;
    font-size: 1rem;
    transition: all 0.7s ease;
  }
  
  .bannerTvIntro__mobile__signup form > button:hover {
    background-color: var(--teleboing-three);
    border: 2px solid var(--teleboing-three);
    color: var(--teleboing-zero); 
    transition: all 0.7s ease;
  }

  .bannerTvIntro__datavideo__mobile {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    width: auto;
    background-color: black;
    z-index: 1;
  }

  .bannerTvIntro__datavideo__author__mobile {
    padding-bottom: 5px;
  }

  .bannerTvIntro__progress__mobile {
    display: flex;
  }

  .bannerTvIntro__progress__counter1__mobile {
    font-size: 1rem;
    font-weight: 200;
    color: var(--teleboing-six);
    padding-right: 10px;
  }
  
  .bannerTvIntro__progress__counter2__mobile {
    font-size: 1rem;
    font-weight: 200;
    opacity: 0.8;
  }

  .bannerTvIntro__datavideo__description__mobile {
    padding: 20px 40px;
    font-size: 1rem;
    opacity: 0.8;
    line-height: 1.6rem;
    font-weight: 200;
    
  }

  .bannerTvIntro__datavideo__description__mobile p > span {
    background-color: #fff;
    margin: 0 10px;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0 10px;
    color: #111;
    border-radius: 10px;

  }

   .bannerTvIntro__datavideo__author h2 {
    font-size: 1.2rem;
    letter-spacing: 1px;
   }

   .bannerTvIntro__datavideo__info {
    width: 100%;
    margin: 0;
    padding: 0;
   }

   .bannerTvIntro__datavideo__info__title {
    display: none;
   }

   .bannerTvIntro__datavideo__info__title h1 {
    font-size: 0.9rem;
    display: flex;
    font-family: Poppins;
    font-weight: 200;
    padding-bottom: 10px;
   }

   .bannerTvIntro__datavideo__info__ticker {
    display: flex;
    
    flex-direction: column;
    width: 100%;
   }

   .bannerTvIntro__timestamp {
    display: none;
   }

   .bannerTvIntro__ticker {
    display: none;
    width: 100%;
    margin: 5px 0;
   }

   .bannerTvIntro__ticker p {
    font-family: Poppins;
    width: 100%;
    height: auto;
    margin: 0;
    font-weight: 400;
    text-align: left;
    justify-content: center;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-animation: overflow-scroll3 20s linear infinite;
            animation: overflow-scroll3 20s linear infinite;
  }
  
  @-webkit-keyframes overflow-scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  
  @keyframes overflow-scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
   .bannerTvIntro__datavideo {
    display: none;
   }

   .bannerTvIntro__nav__contents__right__ticker {
    display: none;
   }

   .bannerTvIntro__nav__contents__ticker {
    width: 100%;
    margin: 0;
   }

   .bannerTvIntro__datavideo__counter {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 5px 0;
   }

   .bannerTvIntro__nav__contents__iconos {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 0;
  }

  .bannerTvIntro__mute {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding-left: 10px;
  }

  .bannerTvIntro__row {
    flex-direction: column;
  }

  .bannerTvIntro__row__poster__props {
    width: 300px;
    height: 100%;
  }

  .bannerTvIntro__row__poster__details {
    max-width: 250px;
    padding-left: 20px;
    padding-top: 10px;
  }

  .bannerTvIntro__row__poster__more {
    margin: 20px 0;
  }

  .bannerTvIntro__row__poster__more > p {
    justify-content: center;
    margin: 0 auto;
  }

}

/* Tablet */
@media (min-width: 900px) and (max-width: 1023px) {
  .bannerTvIntro {
    position: relative;
    height: 560px;
    color: var(--teleboing-zero);
    object-fit: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
  }

  .bannerTvIntro__container__menu {
    display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidomobile {
    display: none;
  }
  .bannerTvIntro__datavideo__info__title {
    display: none;
  }

  .bannerTvIntro__datavideo__mobile {
    display: none;
  }

  .bannerTvIntro__datavideo__description__mobile {
  display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
  display: flex;
  align-items: center;
  margin: auto 0;
  }

  .volume-icon-container {
    align-items: center;
    margin: auto 0;
    display: flex;
  }

  .bannerTvIntro__volumendesktop {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}

/* Laptop */
@media (min-width: 1024px) {
  .bannerTvIntro__container__menu {
    display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidomobile {
    display: none;
  }
  .bannerTvIntro__datavideo__info__title {
    display: none;
  }

  .bannerTvIntro__datavideo__mobile {
    display: none;
  }

  .bannerTvIntro__datavideo__description__mobile {
  display: none;
  }

  .bannerTvIntro__nav__contents__iconos__sonidodesktop {
  display: flex;
  align-items: center;
  margin: auto 0;
  }

  .volume-icon-container {
    align-items: center;
    margin: auto 0;
    display: flex;
  }

  .bannerTvIntro__volumendesktop {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}
.faq {
    padding: 40px 120px;
}

.faq__title {
    font-size: 1.8rem;
    color: var(--teleboing-two);
    text-align: center;
    justify-content: center;
}

.faq__container {
    display: flex;
    flex-direction: column;
}

.faq__item {
    display: flex;
    padding: 40px 0;
}

.faq__item h3 {
    font-size: 1.4rem;
    cursor: pointer;
}

.faq__item > span {
    display: flex;
    font-size: 1rem;
    color: var(--teleboing-two);
    padding: 0 10px;
    align-items: center;
}

.faq__answer {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

/* Mobile */
@media (max-width: 999px) {
    .faq {
        padding: 20px;
    }
}
* {
  margin: 0;
}

.so1screen__box__signup {
  margin: 0;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.so1screen__logo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 120px;
  padding: 0 20px;
  cursor: pointer;
}

.so1screen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.so1screen__signup form {
  display: flex;
  text-align: center;
}

.so1screen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 0.25px solid white;
  background-color: transparent;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.so1screen__signup form > button:hover {
  background-color: #2556cc;
  border: 0.5px solid #111;
  color: #111; 
  transition: all 0.7s ease;
}

.so1screen__container {
  height: 100%;
  display: flex;
}

.so1screen__container2 {
  height: 100%;
  display: flex;
  padding: 0 60px;
}

.so1screen__banner {
  color: #fff;
  /* display: flex; */
  padding: 40px;
  height: 90vh;
  width: auto;
}

.so1screen__poster {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.so1screen__poster__item1 {
  height: auto;
  max-width: 250px;
  padding: 20px 0;
  justify-items: center;
  text-align: center;
}

.so1screen__poster__item2 {
  max-height: 600px;
  width: auto;
  padding: 40px;
}

.so1screen__props {
  display: flex;
  padding: 80px 40px;
}

.so1screen__details {
  width: auto;
  padding: 20px;
}

.so1screen__details__headline__banner {
  font-size: 1.2rem;
  padding: 10px 0 10px 7px;
  opacity: 0.7;
}

.so1screen__details__headline {
  font-size: 1.2rem;
  padding: 10px 0;
  opacity: 0.7;
}

.so1screen__details__title {
  font-size: 2.4rem;
}

.so1screen__details__subinfo {
  opacity: 0.7;
  font-size: 0.9;
  padding: 10px 0 30px 0;
}

.so1screen__details__description__banner {
  font-size: 1.2rem;
  padding: 20px 0 20px 7px;
  line-height: 1.8rem;
  text-shadow: 2px 2px 8px #111;
  max-width: 350px;
}

.so1screen__details__description0 {
  font-size: 1.2rem;
  padding: 0;
  line-height: 1.8rem;
  text-shadow: 2px 2px 8px #111;
}

.so1screen__details__description {
  font-size: 1.4rem;
  padding: 20px 0;
  line-height: 2rem;
}

.so1screen__details__description > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__details__genres {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.so1screen__details__genres > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__details__country {
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.so1screen__details__country > span {
  opacity: 0.7;
  padding-right: 10px;
}

.so1screen__video {
  margin-top: 20px;
}

.so1screen__shoots {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 60px 0 100px 0;
  flex-wrap: wrap;
}

.so1screen__shoots > div {
  width: auto;
  padding: 40px;
}

.so1screen__shoots div img {
  max-width: 350px;
  transition: all 0.5s ease;
}

.so1screen__shoots div img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.5s ease;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .so1screen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .so1screen__logo {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 120px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__signup {
    color: #fff;
    display: grid;
    padding: 10px;
  }
  
  
  .so1screen__poster {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    height: 300px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0;
  }

  .so1screen__poster__item2 {
    height: 300px;
    width: auto;
    padding: 0 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0;
  }

  .so1screen__banner {
    /* flex: none; */
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    /* display: grid; */
    /* flex-direction: column; */
    padding: 20px 0;
  }

  .so1screen__container {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  
  .so1screen__container2 {
    justify-content: center;
    text-align: center;
    padding: 20px 40px;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 10px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__props {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
  }

  .so1screen__video {
    margin: 20px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .so1screen__box__signup {
    position: relative;
    display: grid;
    margin: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 1);
    width: 100%;
  }

  .so1screen__logo {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 120px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__signup {
    color: #fff;
    display: grid;
    padding: 10px;
  }
  
  
  .so1screen__poster {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    height: 300px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__poster__item2 {
    height: 500px;
    width: auto;
    padding: 0 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__banner {
    /* flex: none; */
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    /* display: grid; */
    /* flex-direction: column; */
    padding: 20px 0;
  }

  .so1screen__container {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  
  .so1screen__container2 {
    justify-content: center;
    text-align: center;
    padding: 20px 40px;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 40px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__props {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align: center;
  }

  .so1screen__video {
    margin: 40px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {

  .so1screen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .so1screen__poster__item1 {
    /* height: 500px;
    width: auto; */
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__poster__item2 {
    /* height: 500px;
    width: auto; */
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .so1screen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }

  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 40px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .so1screen__video {
    margin-top: 80px;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .so1screen__details__description__banner {
    font-size: 1.2rem;
    padding: 20px 7px;
    line-height: 1.8rem;
    text-shadow: 2px 2px 8px #111;
  }

  .so1screen__video {
    margin-top: 140px;
  }
}

* {
  margin: 0;
}

.originalsscreen__container {
  height: 100%;
}

.originalsscreen__banner {
  color: #fff;
  display: flex;
  padding: 40px;
}

.originalsscreen__poster__item {
  max-height: 100vh;
  width: auto;
  margin: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.originalsscreen__props {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.originalsscreen__details {
  width: auto;
}

.originalsscreen__details__headline {
  font-size: 1rem;
  padding-bottom: 10px;
  opacity: 0.7;
}

.originalsscreen__details__title {
  font-size: 2.4rem;
}

.originalsscreen__details__subinfo {
  opacity: 0.7;
  font-size: 0.7;
  padding-bottom: 30px;
}

.originalsscreen__details__description {
  font-size: 1.2rem;
  padding-bottom: 20px;
  line-height: 1.7rem;
}

.originalsscreen__details__description > span {
  opacity: 0.7;
  padding-right: 10px;
}

.originalsscreen__details__genres {
  font-size: 1rem;
  padding-bottom: 10px;
}

.originalsscreen__details__genres > span {
  opacity: 0.7;
  padding-right: 10px;
}

.originalsscreen__details__country {
  font-size: 1rem;
  padding-bottom: 20px;
}

.originalsscreen__details__country > span {
  opacity: 0.7;
  padding-right: 10px;
}

.originalsscreen__video {
  margin: 40px 0;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .originalsscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .originalsscreen__poster__item {
    height: 450px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .originalsscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .originalsscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .originalsscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .originalsscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .originalsscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .originalsscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .originalsscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}

.originals__update {
  color: white;
  padding-top: 20px;
  padding-bottom: 60px;
}

.originals__update__container {
  display: flex;
  justify-content: space-between;
}

.originals__update__title {
  padding-left: 40px;
  padding-bottom: 20px;
}

.originals__update__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
}

.originals__update__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
  text-align: center;
}

.originals__update__posters::-webkit-scrollbar {
  display: none;
}

.originals__update__poster {
  object-fit: contain;
  max-height: 250px;
  cursor: pointer;
}

.originals__update__poster__props {
  object-fit: contain;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}

.originals__update__poster__props:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .originals__update__explora {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .originals__update__explora {
    display: none;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
  margin: 0;
}

.watchscreen__container {
  height: 100%;
}

.watchscreen__banner {
  color: #fff;
  display: flex;
  padding: 40px;
}

.watchscreen__poster__item {
  max-height: 350px;
  width: auto;
  margin: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.watchscreen__props {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.watchscreen__details {
  width: auto;
}

.watchscreen__details__headline {
  font-size: 1rem;
  padding-bottom: 10px;
  opacity: 0.7;
}

.watchscreen__details__title {
  font-size: 2.4rem;
}

.watchscreen__details__subinfo {
  opacity: 0.7;
  font-size: 0.7;
  padding-bottom: 30px;
}

.watchscreen__details__description {
  font-size: 1.2rem;
  padding-bottom: 20px;
  line-height: 1.7rem;
}

.watchscreen__details__description > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__details__genres {
  font-size: 1rem;
  padding-bottom: 10px;
}

.watchscreen__details__genres > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__details__country {
  font-size: 1rem;
  padding-bottom: 20px;
}

.watchscreen__details__country > span {
  opacity: 0.7;
  padding-right: 10px;
}

.watchscreen__video {
  margin-bottom: 80px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    height: 450px;
    width: auto;
    padding: 40px 0 20px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .watchscreen__poster {
    flex: none;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .watchscreen__poster__item {
    max-height: 100vh;
    width: auto;
    padding: 40px 0;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .watchscreen__banner {
    flex: none;
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
    object-fit: contain;
    margin-bottom: 0px;
    display: grid;
    flex-direction: column;
    padding: 20px 0;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
  margin: 0;
}

.search__container {
  color: white;
  background-color: #111;
}

.NoSearch__msg {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: center;
  padding: 20px;
  align-self: flex-start;
  background-color: darkgoldenrod;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.recommendedVideos {
  top: 0;
}

.recommendedVideos__msg {
  align-items: center;
  justify-content: center;
  display: flex;
}

.recommendedVideos__msg > h2 {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 40px 0;
}

.recommendedVideos__msg h2 > span {
  font-size: 1.8rem;
  font-weight: 600;
}

.recommendedVideos__videocard > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .recommendedVideos {
    display: block;
    flex-direction: column;
  }
  .recommendedVideos .recommendedVideos__msg {
    padding: 20px 0;
    text-align: center;
  }
  .recommendedVideos .recommendedVideos__videocard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .recommendedVideos {
    display: flex;
    flex-direction: column;
  }
  .recommendedVideos .recommendedVideos__msg {
    padding: 20px 0;
    text-align: center;
  }
  .recommendedVideos .recommendedVideos__videocard {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  } */
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.videoCard {
  color: white;
  width: 400px;
  height: 400px;
  transition: -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  margin: 0 10px;
}

.videoCard:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}

.videoCard > img {
  border-radius: 12px;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.videoCard > p {
  width: 80%;
  padding-top: 5px;
  padding-left: 20px;
}

.videoCard > h2 {
  padding-top: 10px;
  padding-left: 20px;
  width: 80%;
}

.videoCard__title {
  width: 80%;
  font-weight: 400;
  padding-left: 20px;
}

.videoCard__stats {
  display: none;
}

.videoCard:hover > .videoCard__stats {
  display: inline;
}

.videoCard__description {
  display: none;
}

/* Phones */
@media (max-width: 600px) {
  .videoCard {
    color: white;
    width: 100%;
    height: auto;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    margin: 0;
  }

  .videoCard:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .videoCard > img {
    border-radius: 12px;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .videoCard__title {
    width: 100%;
    font-weight: 400;
    padding: 0;
    text-align: center;
    justify-content: center;
  }

  .videoCard__author {
    text-align: center;
    justify-content: center;
  }

  .videoCard:hover > .videoCard__stats {
    display: inline;
  }

  .videoCard__description {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}

.profile {
  color: white;
  margin: 0;
}

.profile__container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  width: auto;
  justify-content: center;
}

.profile__data {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}

.profile__data__img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile__data__img img {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  justify-content: center;
  margin: 10px auto;
}

.profile__data__img > input {
  border-radius: 2.5px;
}

.profile__data__img button {
  width: 250px;
  padding: 20px;
  margin: 20px 0;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 10px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.profile__data__img > button:hover {
  background-color: #2556cc;
}

.profile__data form > ul {
  padding: 20px 0;
}

.profile__data form ul > li {
  padding: 10px 0;
  display: flex;
}

.profile__data form {
  padding-top: 20px;
}

.profile__data form ul li > p {
  padding-right: 5px;
}

.profile__data form ul li > input {
  padding: 7px;
  margin: 0 5px;
  height: 12px;
  border: none;
  border-radius: 5px;
}

.profile__data form ul li > input::-webkit-input-placeholder {
  color: #111;
}

.profile__data form ul li > input::placeholder {
  color: #111;
}

.profile__data__updated {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.profile__data__updated ul {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.profile__data__updated ul li {
  display: flex;
  justify-content: center;
  width: auto;
}

.profile__data__updated ul button {
  display: flex;
  width: 250px;
  padding: 20px;
  margin: 20px auto;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 10px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  justify-content: center;
}

.profile__data__updated ul > button:hover {
  background-color: #2556cc;
}

input {
  color: #111;
}

.profile__interestingvideos {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__sub__data {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: center;
  margin: 0 auto;
}

.profile__sub__data ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.profile__sub__data ul li {
  padding: 10px 0;
}

.profile__sub__data ul li > p {
  font-size: 1.2rem;
  font-weight: 200;
}

.profile__sub__data ul li p > span {
  font-weight: 400;
  color: var(--teleboing-two);
}

.profile__sub__cancel {
  display: flex;
  width: 250px;
  padding: 20px;
  margin: 40px auto;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--teleboing-two);
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  justify-content: center;
}

/* CSS for the tab structure */
.tabs-container {
  width: 100%;
}

.tabs-header {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  width: 100%;
  border-radius: 20px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.tabs-header::-webkit-scrollbar {
  display: none;
}

.tab {
  background-color: transparent;
  border: solid 2px var(--teleboing-two);
  border-radius: 20px;
  padding: 15px 30px;
  cursor: pointer;
  width: auto;
  font-size: 1.2rem;
}

.tab.active {
  background-color: var(--teleboing-two);
  color: var(--teleboing-zero);
}


/* Phones and Tablet*/
@media (max-width: 799px) {
  .tabs-header {
    display: flex;
    justify-content: left;
    padding: 20px 10px;
    width: 100%;
    border-radius: 20px;
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

/* Tablet and small laptop */
@media (min-width: 800px) {
}

/* Laptop */
@media (min-width: 900px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.column {
  color: white;
  max-width: 800px;
  /*  padding-left: 20px; */
}

.column__container {
  text-align: center;
  justify-content: center;
  /* justify-content: space-between; */
}

/* .column__container__player {
  z-index: 99;
  top: 80px;
  position: sticky;
  align-self: flex-start;
  padding: 20px 0;
} */

.column__posters {
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
  text-align: center;
  justify-content: center;
}

.column__posters::-webkit-scrollbar {
  display: none;
}

.column__poster {
  max-width: 800px;
}

.column__poster__props {
  height: 450px;
  /* max-width: 450px; */
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}

.column__poster__props:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}

.column__poster__title {
  /* max-width: 400px; */
  padding-top: 10px;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
}

.column__poster__details {
  max-width: 350px;
  display: none;
  transition: all ease 0.5s;
  text-align: center;
}

.column__poster__props:hover > .column__poster__details {
  display: inline;
}

.column__poster__description {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 20px;
  width: 400px;
  justify-content: center;
  text-align: center;
}

/* .column__poster__stats {
  padding-left: 20px;
} */

/* .column__banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0.8vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  padding-bottom: 1rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.column__banner__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}
 */

 @media (max-width: 799px) {
  .column {
    width: 100%;
  } 

  .column__poster {
    width: 350px;
  }

  .column__poster__props {
    height: auto;
  }
}
* {
  color: white;
}
    
.tv {
  display: flex;
  margin: 0;
  padding: 20px;
}

.tv__player__container {
  flex: 0.8 1;
  width: 100%;
  height: 1000px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 60px;
  z-index: 99;
}

.tv__player {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tv__player > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__player__buttons {
  display: flex;
  padding: 20px 0;
  justify-content: center;
}

.tv__player__buttons > button {
  margin: 0 10px;
  background-color: #dbd1b4;
  padding: 10px 20px;
  color: #111;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transform: all 0.5s ease;
          transform: all 0.5s ease;
}

.tv__player__buttons > button:hover {
  background-color: #2556cc;
  color: white;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.tv__player__details__content {
  padding: 20px;
}

.tv__player__details__content > h1 {
  padding: 20px 0;
  font-size: 1.8rem;
}

.tv__player__details__content__author {
  font-size: 1rem;
  opacity: 0.7;
  font-weight: 200;
}

.tv__player__details__content__description {
  font-size: 1.2rem;
  padding-bottom: 10px;
  line-height: 1.6rem;
  font-weight: 200;
}

.tv__player__details__content__director,
.tv__player__details__content__starring {
  font-size: 1rem;
  padding: 10px 0;
}

.tv__player__details__content__director > span,
.tv__player__details__content__starring > span {
  opacity: 0.7;
}

.tv__player__details__content__stats {
  padding: 10px 0;
  font-size: 1.2rem;
}

.tv__videolist {
  flex: 0.2 1;
  padding: 0 20px;
}

.tv__videolist > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__videolist__container {
  width: 90%;
  padding: 20px;
  flex-direction: column;
}

/* .tv__videolist__posters {
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
} */

/* .tv__videolist__posters::-webkit-scrollbar {
  display: none;
} */

.tv__videolist__poster {
  max-height: 200px;
  border-radius: 12px;
}

.tv__videolist__poster__props {
  max-width: auto;
  height: 320px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}

.tv__videolist__poster__props:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.tv__videolist__poster__title {
  max-width: 200px;
  padding-left: 20px;
  padding-top: 10px;
}

.tv__videolist__poster__details {
  max-width: 250px;
  display: inline;
  transition: all ease 0.5s;
  font-size: 0.8rem;
}

/* .tv__videolist__poster__props:hover > .tv__videolist__poster__details {
  display: inline;
} */

.tv__videolist__poster__genres {
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  max-width: 250px;
}

.tv__videolist__poster__synopsis {
  display: none;
}

.tv__videolist__poster__stats {
  padding-left: 20px;
  max-width: 250px;
}


/* Phones and Tablet*/
@media (max-width: 600px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1 1;
    top: 0px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transform: all 0.5s ease;
            transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1 1;
    top: 0px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transform: all 0.5s ease;
            transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
    
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .tv {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__container {
    flex: 1 1;
    top: 60px;
    background-color: #111;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    object-fit: contain; */
  }
  
  .tv__player > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tv__player__buttons {
    display: flex;
    padding: 20px 0;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
  }

  .tv__player__buttons > button {
    margin-left: 5px;
    background-color: #dbd1b4;
    padding: 10px 20px;
    color: #111;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transform: all 0.5s ease;
            transform: all 0.5s ease;
  }

  .tv__player__buttons::-webkit-scrollbar {
    display: none;
  }

  .tv__player__iframe iframe {
    height: 200px;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .tv__player__details__content {
    padding: 0;
    display: none;
  }
  
  .tv__videolist {
    flex: 1 1;
    padding: 0;
    margin: 0;
  }
  
  .tv__videolist > p {
    padding-top: 20px;
    padding-left: 0;
    width: 300px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .tv__videolist__container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .tv__videolist__poster {
    width: 280px;
    height: auto;
    border-radius: 0px;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

  }
  
  .tv__videolist__poster__props {
    width: 100%;
    height: 100%;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .tv__videolist__poster__props:hover {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
  
  .tv__videolist__poster__title {
    width: 300px;
    height: auto;
    margin: 0 auto;
    font-size: 1rem;
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .tv__videolist__poster__details {
    max-width: 250px;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
    
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
    display: none;
  }

  .tv__videolist__poster__synopsis {
    display: flex;
    width: 300px;
    font-size: 0.9rem;
    font-weight: light;
    padding: 10px 0;
    margin: 0 auto;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: 250px;
  }
}

/* Desktop */
@media (min-width: 1300px) {
  .tv {
    display: flex;
    margin: 0;
    padding: 20px;
  }

  /* .tv__player {
    flex: 0.8;
    position: -webkit-relative;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    padding-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  } */

  .tv__videolist {
    flex: 0.2 1;
    padding: 0 20px;
  }
  
  .tv__videolist > p {
    padding: 0 0 20px 20px;
    font-size: 1.2rem;
    width: 150px;
  }
  
  .tv__videolist__container {
    width: 80%;
    padding: 0;
    flex-direction: column;
  }
  
  .tv__videolist__poster {
    max-height: 150px;
    border-radius: 12px;
  }
  
  .tv__videolist__poster__props {
    max-width: auto;
    height: 250px;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
  }
  
  .tv__videolist__poster__props:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  .tv__videolist__poster__title {
    max-width: auto;
    padding-left: 20px;
    padding-top: 10px;
  }
  
  .tv__videolist__poster__details {
    max-width: auto;
    display: inline;
    transition: all ease 0.5s;
    font-size: 0.8rem;
  }
  
  .tv__videolist__poster__genres {
    font-weight: 400;
    padding-left: 20px;
    padding-top: 10px;
    max-width: 250px;
  }
  
  .tv__videolist__poster__stats {
    padding-left: 20px;
    max-width: auto;
  }
}


.banner {
  height: 100vh;
  width: 100%;
}

.planscreen {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.planscreen__container {
  color: #fff;
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: top;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  height: auto;
  width: auto;
  border-radius: 15px;
}

.planscreen__msg > span {
  opacity: 0.8;
  font-size: 1.2rem;
}

.planscreen__msg > p {
  font-size: 1.8rem;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
}

.planscreen__options ul {
  display: flex;
  justify-content: center;
  text-align: center;
}

.planscreen__options ul button {
  padding: 10px 20px;
  margin: 20px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  transition: all 0.5s ease;
}

.planscreen__options ul button:hover {
  background-color: #2556cc;
}

.planscreen__options ul li > p {
  padding-right: 20px;
  font-size: 1.8rem;
}

.planscreen__options__price {
  font-size: 1.8rem;
  padding: 10px 0;
}

.planscreen__options__price span {
  font-size: 1rem;
}

.planscreen__punchline {
  padding: 20px 0;
}

.planscreen__punchline p {
  font-size: 1.2rem;
  font-weight: 200;
  color: var(--teleboing-three);
}

.planscreen__help {
  background-color: #111;
  width: 100%;
  height: auto;
  padding: 10px 0;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.planscreen__help p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.planscreen__help span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones */

@media (max-width: 600px) {
  .planscreen__options ul {
    flex-direction: column;
  }

  .planscreen__options ul button {
    margin: 10px 0;
  }

  .planscreen__help {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {

  .banner {
    height: 100%;
    width: 100%;
  }

  .planscreen {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100%;
    width: 100%;
  }

  .planscreen__container {
    color: #fff;
    display: flex;
    padding: 50px;
    justify-content: center;
    align-items: top;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    height: auto;
    width: auto;
    border-radius: 0;
  }

  .planscreen__options ul {
    flex-direction: column;
  }

  .planscreen__options ul button {
    margin: 10px 0;
  }

  .planscreen__help {
    flex-direction: column;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}

.newuser__banner {
  height: 100vh;
  width: 100%;
}

.newuser {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.newuser__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.newuser__msg > span {
  opacity: 0.8;
  font-size: 1.2rem;
}

.newuser__msg__approvedpay {
  font-size: 1.8rem;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
}

.newuser__msg__subcode {
  font-size: 1.4rem;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.newuser__msg__subcode > span {
  color: var(--teleboing-two);
}

.newuser__msg__enterdata {
  font-size: 1.4rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.newuser__container form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.newuser__container form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.newuser__container form input::-webkit-input-placeholder {
  color: #111;
}

.newuser__container form input::placeholder {
  color: #111;
}

.newuser__formpassword {
  display: flex;
}

.newuser__formpassword > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.newuser__formpassword > input::-webkit-input-placeholder {
  color: #111;
}

.newuser__formpassword > input::placeholder {
  color: #111;
}

.newuser__formpassword button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  padding: 0 10px;
}

.newuser__container form > button {
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.newuser__container form > button:hover {
  background-color: #2556cc;
}

.newuser__container > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.newuser__help {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.newuser__help ul li {
  padding: 5px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

/* Phones */
@media (max-width: 799px) {
  .newuser__banner {
    height: 100vh;
    width: 100%;
    margin-bottom: 40px;
  }

  .newuser {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .newuser__container {
    padding: 40px;
    width: 100%;
  }

  .newuser__msg__approvedpay {
    font-size: 1.5rem;
    padding: 20px;
  }

  .newuser__msg__subcode {
    font-size: 1.2rem;
  }
  
  .newuser__msg__enterdata {
    font-size: 1.2rem;
    padding: 0;
  }

  .newuser__container form {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
  }

  .newuser__container form > input {
    width: 100%;
    margin: 10px 0;
  }

  .newuser__container form input::-webkit-input-placeholder {
    color: #111;
  }

  .newuser__container form input::placeholder {
    color: #111;
  }

  .newuser__container form > button {
    margin: 10px 0;
    width: 100%;
  }

  .newuser__help {
    padding-top: 0;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}

.banner {
  height: 100vh;
  width: 100%;
}

.signin {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.signin__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.signin__msg {
  font-size: 1.8rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.signin__container form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.signin__container form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  /* max-width: 220px; */
  border-radius: 5px;
  margin: 0 2px;
}

.signin__container form input::-webkit-input-placeholder {
  color: #111;
}

.signin__container form input::placeholder {
  color: #111;
}

.signin__container form > button {
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.signin__container form > button:hover {
  background-color: #2556cc;
}

.signin__container > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.signin__formpassword {
  display: flex;
}

.signin__formpassword > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
}

.signin__formpassword > input::-webkit-input-placeholder {
  color: #111;
}

.signin__formpassword > input::placeholder {
  color: #111;
}

.signin__formpassword button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  padding: 0 10px;
}

.signin__help__password > span {
  padding-left: 10px;
}

.signin__help__signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.signin__help__signup p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.signin__help__signup span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones and Tablet*/

@media (max-width: 799px) {
  .signin {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .signin__container {
    color: #fff;
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    max-height: 600px;
    max-width: 600px;
    border-radius: 10px;
  }

  .signin__msg {
    padding-bottom: 0px;
  }

  .signin__container form {
    flex-direction: column;
    width: 100%;
  }

  .signin__container form > input {
    width: 100%;
    margin: 10px 0;
  }

  .signin__container form > button {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
  }

  .signin__formpassword {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .signin__formpassword > input {
    display: flex;
    width: 100%;
  }

  .signin__help__password > span {
    padding-left: 10px;
  }

  .signin__help__signup {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  /* .signin {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(17, 17, 17, 0);
    height: 100vh;
    width: 100%;
  }

  .signin__container {
    color: #fff;
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    max-height: 600px;
    max-width: 600px;
    border-radius: 10px;
  }

  .signin__msg {
    padding-bottom: 0px;
  }

  .signin form {
    flex-direction: column;
  }

  .signin form > input {
    max-width: 250px;
    margin: 10px 0;
  }

  .signin form > button {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
  }

  .signin__help__password > span {
    padding-left: 10px;
  }

  .signin__help__signup {
    flex-direction: column;
  } */
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}

.banner {
  height: 100vh;
  width: 100%;
}

.resetpassword {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.resetpassword__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.resetpassword__msg {
  font-size: 1.8rem;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.resetpassword form {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.resetpassword form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  width: auto;
  border-radius: 5px;
}

.resetpassword form input::-webkit-input-placeholder {
  color: #111;
}

.resetpassword form input::placeholder {
  color: #111;
}

.resetpassword form > button {
  width: auto;
  padding: 10px 10px;
  margin: 20px 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.resetpassword form > button:hover {
  background-color: #2556cc;
}

.resetpassword > button {
  padding: 10px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
  font-weight: 300;
  border: none;
  cursor: pointer;
}

.resetpassword form > p {
  font-size: 1rem;
  opacity: 0.8;
  margin-top: 10px;
}

.resetpassword__help__password > span {
  padding-left: 10px;
}

.resetpassword__help__signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.resetpassword__help__signup p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.7;
}

.resetpassword__help__signup span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones and Tablet*/

@media (max-width: 600px) {
  .resetpassword__help__password > span {
    padding-left: 10px;
  }

  .resetpassword__help__signup {
    flex-direction: column;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}

